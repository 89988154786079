.billet-bullet {
    position: absolute;
    color:$white-1;
    display: flex;
    align-items: center;
    cursor: pointer;


    .bullet-highlight {
      background: rgba(243, 112, 33, 0.3);
      height: 30px;
      margin-left: 12px;
      border: 1px solid $orange-9;

      &:before {
        content: '';
        top: 5px;
        border-color: transparent $orange-9 transparent transparent;
        border-width: 6px;
        content: '';
        display: block;
        position: relative;
        left: -12px;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &:after {
        content: '';
        display: block;
        position: relative;
        left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        top: -6px;
        border-color: transparent #f1cab3 transparent transparent;
        border-width: 5px;
      }
    }

    &.bullet-1 {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
        top:409px;
        left:195px;

        .bullet-highlight {
        min-width: 453px;
        }
      }

      @media screen and (min-width: 992px) {
        top:538px;
        left:268px;

        .bullet-highlight {
          min-width: 589px;
        }
      }

      @media screen and (min-width: 1160px) {
        top: 219px;
        left: 190px;

        .bullet-highlight {
          min-width: 443px;
        }
      }
      
      @media screen and (min-width: 1600px) {
        top: 219px;
        left: 272px;

        .bullet-highlight {
          min-width: 585px;
        }
      }
    }

    &.bullet-2 {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
        top: 481px;
        left: 528px;

        .bullet-highlight {
          min-width: 118px;
        }
      }

      @media screen and (min-width: 992px) {
        top:633px;
        left:703px;

        .bullet-highlight {
          min-width: 155px;
        }
      }

      @media screen and (min-width: 1160px) {
        top: 293px;
        left: 518px;

        .bullet-highlight {
          min-width: 115px;
        }
      }

      @media screen and (min-width: 1600px) {
        top: 303px;
        left: 707px;

        .bullet-highlight {
          min-width: 150px;
        }
      }
    }

    &.bullet-3 {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
        order: -1;
        top: 457px;
        left: 23px;

        .right {
          min-width: 420px;
        }

        .bullet-highlight {
          margin: 0;
          height: 30px;

          &:before {
            top: 7px;
            border-color: transparent transparent transparent $orange-9;
            left: 419px;
          }

          &:after {
            left: 418px;
            top: -4px;
            border-color: transparent transparent transparent #f1cab3;
          }
        }
        
        span {
          margin-left: 8px;
        }
      }

      @media screen and (min-width: 992px) {
        top: 603px;
        left: 30px;

        .right {
          min-width: 545px;
        }

        .bullet-highlight {
          &:before {
            left: 544px;
          }

          &:after {
            left: 543px;
          }
        }
      }

      @media screen and (min-width: 1160px) {
        top: 270px;
        left: 22px;

        .right {
          min-width: 410px;
        }

        .bullet-highlight {
          &:before {
            left: 409px;
          }

          &:after {
            left: 408px;
          }
        }
      }

      @media screen and (min-width: 1600px) {
        top: 275px;
        left: 33px;

        .right {
          min-width: 540px;
        }

        .bullet-highlight {
          &:before {
            left: 539px;
          }

          &:after {
            left: 537px;
          }
        }
      }
    }

    &.bullet-4 {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
        order: -1;
        top: 640px;
        left: 25px;

        .right {
          min-width: 245px;
        }

        .bullet-highlight {
          margin: 0;
          height: 30px;

          &:before {
            top: 7px;
            border-color: transparent transparent transparent $orange-9 ;
            left: 244px;
          }

          &:after {
            left: 243px;
            top: -4px;
            border-color: transparent transparent transparent #f1cab3;
            border-width: 5px;
          }
        }

        span {
          margin-left: 8px;
        }

      }

      @media screen and (min-width: 992px) {
        top: 840px;
        left: 30px;

        .right {
          min-width: 330px;
        }

        .bullet-highlight {
          &:before {
            left: 329px;
          }

          &:after {
            left: 328px;
          }
        }
      }

      @media screen and (min-width: 1160px) {
        top: 447px;
        left: 25px;

        .right {
          min-width: 245px;
        }

        .bullet-highlight {
          &:before {
            left: 244px;
          }

          &:after {
            left: 243px;
          }
        }
      }

      @media screen and (min-width: 1600px) {
        top: 482px;
        left: 33px;

        .right {
          min-width: 330px;
        }

        .bullet-highlight {
          &:before {
            left: 329px;
          }

          &:after {
            left: 327px;
          }
        }
      }
    }

    span {
      background-color: $orange-9;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      min-width:32px;
      height:32px;
    }
  }