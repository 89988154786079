.redefine-password {
  max-width: 900px;
  margin: 50px auto 0 auto;
  font-size: 0;

  &.totp{

    .form-box {
      display: inline-block;
      position: relative;
      width: calc(50% - 10px);
      vertical-align: top;
      font-size: 14px;
      text-align: left;

      &:first-child {
        margin-right: 0px;
      }

      @media all and (max-width: $screen-sm-until) {
         width: 100%;
         max-width: 430px;

         &:first-child {
           margin-right: 0;
         }
      }
      fieldset{
        border: none;
        padding: 0;
        transition: border-color .2s linear;

        .totp-info, .sms-info{
          padding: 10px;

          &.checked{
            border-radius: 5px;
            border: 1px solid #eeeeee;
          }
        }

        p{
          color: $black-1;
          font-size: 15px;
          font-weight: 400;
        }

        .gn-radio {
          font-size: 13px;
          :before {
            margin-right: 10px;
          }
        }
        .form-group {
          text-align: left;
          padding: 0;
          margin: 10px 0 0 0;
          .input-alert {
            right: 0;
          }
          label{
            font-size: 14px;
          }
          input {
            width: 100%;
            max-width: 100%;
            text-align: left;
          }
        }
      }
    }

  }

  .form-box {
    display: inline-block;
    position: relative;
    width: calc(50% - 10px);
    vertical-align: top;
    font-size: 14px;

    &:first-child {
      margin-right: 20px;
    }

    @media all and (max-width: $screen-sm-until) {
      width: 100%;
      max-width: 430px;

      &:first-child {
        margin-right: 0;
      }
    }

    h2 {
      font-size: 20px;
      color: $orange-1;
      margin-top: 0;
      text-align: left;
    }

    p {
      text-align: left;
    }

    fieldset {
      padding: 20px;
      border: 2px solid $gray-4;
      transition: border-color .2s linear;

      &:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        background-color: #fff;
        transition: opacity .2s linear;
      }

      &[disabled] {
        border-color: transparent;

        &:after {
          opacity: 0.5;
          z-index: 1;
        }
      }

      .btn-blue-primary {
        margin-top: 14px;
      }
    }

  }

  .code-box {
    font-size: 14px;
    .form-group {
      text-align: left;

      .input-alert {
        right: calc(50% - 70px);
      }
    }

    .receive-call {
      border: 1px solid #eee;
      background: #fff;
      padding: 10px;
      min-height: 88px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .btn-blue-primary {
        margin-top: 0px;
      }

      .text-call {
        font-weight: bold;
      }

      .ripple-loading {
        width: 60px;
        height: 60px;
        fill: $orange-1;
        stroke: $orange-1;
      }

      .calling {
        display: inline-block;
        vertical-align: top;
        padding-top: 22px;
      }

      .question {
        font-weight: bold;
      }
    }

    .continue-btn {
      margin-top: 44px;
      margin-bottom: -20px;
    }
  }

  .redefine-box {

    .gn-password-hint {
      color: #6E6F71;
      font: normal normal bold 11px/12px Arial;
    }

    .gn-password-strength {
      .point {
        @media all and(max-width: 769px) {
          margin-right: 3px;
        }
      }
    }

    .form-group {
      max-width: 380px;
      color: #6E6F71;

      .input-icons i {
        position: absolute;
      }

      .input-icons {
        width: 100%;
      }

      .eye {
        padding: 10px;
        min-width: 40px;
        left: 340px;
        cursor: pointer;
        @media all and(min-width: 768px) and (max-width: 1023px){
          left: 280px;
        }
        @media all and(min-width: 421px) and (max-width: 767px){
          left: 320px;
        }
        @media all and(max-width: 420px){
          left: 270px;
        }
        @media all and(max-width: 321px){
          left: 220px;
        }
      }

      .form-control {
        width: 100%;
        padding: 10px;
        text-align: left;
      }

      .info-icon {
        color: #00b4c5;
        font-size: 18px;
        position: relative;
        cursor: pointer;
        top: 2px;
        margin-left: 6px;
      }

      .same-password-error {
        display: inline-block;
        width: 100%;
        text-align: right;
        font-size: 11px;
        color: #b50000;
      }
    }

    .random-passwd {
      min-height: 30px;
      margin-top: 30px;

      div {
        margin-top: 10px;
      }

      input {
        background: $gray-4;
        border: none;
        padding: 5px;
        text-align: center;
        width: 120px;
        margin-right: 10px;
      }
    }
  }
  .btn {
    padding: 8px 12px;
  }
}

.btn-define {
  margin-top: 50px;
  font-size: 14px;
}

.modal-dialog {
  @media all and(max-width: 767px){
    position: absolute;
    width: 100% !important;
    bottom: 0;
    margin: 0;
  }
}
