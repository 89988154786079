.account-error {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 50px;

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    @media all and (max-width: $screen-sm-until) {
      font-size: 25px;
      text-align: center;
    }
  }

  p {
    width: 80%;

    @media all and (max-width: $screen-md-until) {
      width: 100%;
    }
  }

  .error-message {
    margin: 20px 0;
    color: $red-1;
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
    width: 60%;

    i {
      font-size: 25px;
      vertical-align: middle;
    }

    @media all and (max-width: $screen-md-until) {
      width: 100%;
    }
  }

  .enter {
    margin-top: 20px;
    text-align: center;
  }

  .detail {
    height: 200px;
  }

  .action {
    text-align: center;
  }

  .kernel-message {
    border: 1px solid $gray-3;
    padding: 20px 20px;
    border-radius: 3px;
  }
}