.refund {
  max-width: $screen-lg-until;
  color: $gray-1;

  table {
    margin-bottom: 0;
  }

  .gn-loading {
    width: 100%;
    min-height: 300px;
    padding-top: 100px;
  }

  .refund-header {
    margin-top: 30px;
    font-size: 18px;
    p {
      margin: 20px 0px;
    }
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;
  }

  .subtitle {
    font-size: 25px;
    color: $orange-1;
    font-family: $font-family-2;
  }

  .seller, .table-responsive {
    vertical-align: middle;
    h3 {
      margin-top: 0;
    }

    .name {
      border-bottom: 1px solid $gray-3;
      padding-top: 5px;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .address, .phone {
      margin-bottom: 20px;

      span, i {
        vertical-align: top;
        display: inline-block;
      }

      span {
        width: 300px;
      }
    }

    .icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .table-responsive {
    border-bottom: 1px solid $gray-3;
    margin-bottom: 20px;
  }

  .seller {
    margin-top: 50px;
  }

  .refund-data {
    .icon-meu-dinheiro, .icon-calendar {
      font-size: 30px;
      color: $gray-3;
    }

    .value {
      display: block;
      font-weight: bold;
    }
  }

  .verify-section {
    margin-top: 30px;
  }

  form {
    padding: 0 40px;
    margin-top: 20px;
    .form-field {
      margin-right: 20px;
    }

    .verify {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .refund-form {
    color: $gray-1;

    .gn-loading-brand {
      width: 100%;
      padding: 150px;
    }

    .title {
      color: $orange-1;
      font-family: $font-family-2;
    }

    .subtitle {
      font-weight: bold;
      font-size: 20px;
      margin: 20px 0 30px;
    }

    label {
      font-weight: normal;
    }

    .with-space {
      @media all and(min-width: $screen-sm) {
        padding-right: 15px;
      }
    }

    .terms {
      font-size: 12px;
      margin: 0 0 15px;
    }

    .box {
      text-align: left;
      border: 1px solid $gray-4;
      border-radius: 2px;
      padding: 15px 20px;
      margin-bottom: 20px;

      .box-title {
        font-weight: bold;
        font-size: 16px;
      }

      .row {
        padding-left: 20px;
      }
    }

    .form-data {
      .person-type {
        display: block;
      }

      .checkbox-inline {
        padding-left: 0;
        margin-left: 0;
      }

      .separator {
        line-height: 30px;
      }

      .gn-radio {
        display: inline-block;
      }

      .terms {
        text-align: left;
        border: 1px solid $gray-4;
        border-radius: 2px;
        padding: 15px 20px;
        font-size: 14px;

        .gn-checkbox {
          display: inline-block;
          vertical-align: top;
        }
        .term-label {
          width: 95%;
        }
      }

    }
  }

  .verify-error {
    color: $red-1;
    font-size: 12px;
    padding: 10px 20%;
  }

  .main-btn {
    min-width: 200px;
    padding: 10px 0;

    &.btn-success {
      cursor: default;

      &:hover, &:active, &:focus {
        background: $green-1;
      }

      .icon-check {
        font-size: 21px;
        display: inline-block;
        vertical-align: bottom;
        padding-right: 10px;
      }
    }

    &.ng-hide {
      display: none;
    }
  }

  @media screen and (max-width: $screen-sm-until) {
    .refund-data, .address, .phone {
      text-align: center;
      span {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: $screen-md-until) {
    // max-width: $screen-md-until;

    padding: 0 30px !important;

    .address, .phone {
      margin-bottom: 20px;

      span {
        width: 100%;
      }
    }

    .refund-header {
      margin-top: 30px;
      padding: 0 50px;
      text-align: center;
      .title {
        font-size: 35px;
      }
    }
  }
}
