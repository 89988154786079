.contestation-recovered {
  p {
    margin: 21px 0;
  }

 .title {
    color: $green-1;
    font-size: 21px;
    vertical-align: middle;
    font-weight: 600;
    font-family: 'Open Sans';

    .icon {
      font-size: 30px;
      vertical-align: middle;
    }
 }

 .contestation-link {
    margin-top: 35px;
  }
}
