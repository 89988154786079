.auth {
  text-align: center;
  padding: 50px 10px 0 10px;
  min-width: 320px;

  h1 {
    color: $orange-1;
    margin: 40px 0 20px 0;
    font-size: 42px;
    @media all and(max-width: 768px){
      font-size: 34px;
    }
  }

  .form-group {
    margin: 26px auto;
    text-align: left;
    position: relative;
  }

  .input-alert {
    position: absolute;
    right: 0;
  }

  .auth-content { 
    padding-bottom: 50px;
    border-bottom: 1px solid $gray-4;
  }

  .help-links {
    padding-top: 14px;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;

    a {
      display: block;
      margin: 16px;
    }
  }

  .white-box {
    background: #fff;
  }

  .error-box {
    background: $red-1;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    margin: 16px 0;
    position: relative;
    z-index: -1;
    transition: transform .2s ease-out, opacity .2s ease-out;

    &:first-letter { 
      text-transform: uppercase;
    }

    &.ng-hide {
      opacity: 0;
      transform: translateY(-150%);
    }

  }

}