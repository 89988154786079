.subscription-cancel {
  max-width: $screen-sm-until;
  color: $gray-1;

  .gn-loading-brand {
    width: 100%;
    padding: 150px;
  }

  p {
    margin-bottom: 30px;
  }

  img {
    display: inline-block;
    width: 150px;
    height: 150px;
    vertical-align: top;
    margin-right: 20px;

    @media all and (max-width: $screen-sm-until) {
      margin: 0 auto;
      display: block;
    }
  }

  form {
    display: inline-block;
    vertical-align: middle;
    width: 60%;

    @media all and (max-width: $screen-sm-until) {
      width: 100%;
    }

    .action {
      margin-top: 20px;
    }

    .captcha {
      width: 65%;
    }
  }
}
