@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/Open-Sans-300/Open-Sans-300.eot');
  src: url('/fonts/Open-Sans-300/Open-Sans-300.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light'),
       local('Open-Sans-300'),
       url('/fonts/Open-Sans-300/Open-Sans-300.woff2') format('woff2'),
       url('/fonts/Open-Sans-300/Open-Sans-300.woff') format('woff'),
       url('/fonts/Open-Sans-300/Open-Sans-300.ttf') format('truetype'),
       url('/fonts/Open-Sans-300/Open-Sans-300.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Open-Sans-regular/Open-Sans-regular.eot');
  src: url('/fonts/Open-Sans-regular/Open-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Open Sans'),
       local('Open-Sans-regular'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.woff2') format('woff2'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.woff') format('woff'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.ttf') format('truetype'),
       url('/fonts/Open-Sans-regular/Open-Sans-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/Open-Sans-600/Open-Sans-600.eot');
  src: url('/fonts/Open-Sans-600/Open-Sans-600.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Semibold'),
       local('Open-Sans-600'),
       url('/fonts/Open-Sans-600/Open-Sans-600.woff') format('woff'),
       url('/fonts/Open-Sans-600/Open-Sans-600.ttf') format('truetype'),
       url('/fonts/Open-Sans-600/Open-Sans-600.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Open-Sans-700/Open-Sans-700.eot');
  src: url('/fonts/Open-Sans-700/Open-Sans-700.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Bold'),
       local('Open-Sans-700'),
       url('/fonts/Open-Sans-700/Open-Sans-700.woff2') format('woff2'),
       url('/fonts/Open-Sans-700/Open-Sans-700.woff') format('woff'),
       url('/fonts/Open-Sans-700/Open-Sans-700.ttf') format('truetype'),
       url('/fonts/Open-Sans-700/Open-Sans-700.svg#OpenSans') format('svg');
}

@font-face {
    font-family: 'Bebas Neue Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bebas Neue Regular'), url('/fonts/BebasNeue/BebasNeueRegular.woff') format('woff');
}

@font-face {
    font-family: 'Bebas Neue Book';
    font-style: normal;
    font-weight: normal;
    src: local('Bebas Neue Book'), url('/fonts/BebasNeue/bebasneue_book.woff') format('woff');
}

@font-face {
    font-family: 'Bebas Neue Light';
    font-style: normal;
    font-weight: normal;
    src: local('Bebas Neue Light'), url('/fonts/BebasNeue/BebasNeueLight.woff') format('woff');
}

@font-face {
    font-family: 'MyriadPro Regular';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/MyriadPro/MyriadPro-Regular.eot');
    src: url('/fonts/MyriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/MyriadPro/MyriadPro-Regular.woff') format('woff'),
        url('/fonts/MyriadPro/MyriadPro-Regular.ttf') format('truetype');
}


