.account-phone {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 50px;

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    @media all and (max-width: $screen-sm-until) {
      font-size: 25px;
      text-align: center;
    }
  }

  form {
    padding: 30px 0;
    width: 80%;

    @media all and (max-width: $screen-md-until) {
      padding: 15px 0;
      width: 100%;
    }

    p {
      width: 70%;
      margin-bottom: 20px;
      font-size: 16px;

      i {
        font-size: 18px;
      }

      @media all and (max-width: $screen-md-until) {
        width: 100%;
      }
    }

    .form-group {
      display: inline-block;
      padding-right: 30px;
      width: 30%;

      @media all and(max-width: $screen-sm-until){
        width: 100%;
        padding: 0;
      }
    }
  }

  .actions {
    .btn {
      margin-top: 10px;
      margin-right: 15px;
    }

    @media all and(max-width: $screen-xs-until){
      text-align: center;
    }
  }
}
