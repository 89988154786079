.contestation-new {

  .row {

    p {
      margin-bottom: 20px;
    }

    .btn {
      border-width: 2px;
      padding: 6px 26px;
    }
  }

  .checkbox-inline {
    padding-left: 0;
    margin-bottom:10px;

    .radio, .gn-radio {
      display: inline-block;
    }
  }

  .charge-data {
    border-top: 2px solid $gray-4;
    border-bottom: 2px solid $gray-4;
    margin: 10px 0 20px 0;
    padding: 4px 0 20px 0;

    .box {
      margin-top: 10px;

      .checkbox-inline {
        display: block;

        .gn-radio {
          padding-top: 0 !important;
          vertical-align: top;
          margin-top: 9px;
        }

        .radio {
          font-size: 18px;
          font-family: $font-family-2;
          color: $orange-1;
          width: calc(100% - 41px);
        }
      }

      .icon-question-circle {
        color: $blue-1;
        font-size: 16px;
        position: absolute;
        top: 15px;
        right: 20px;
      }

      .tooltip-arrow {
        right: 24px;
        left: auto;
      }

      .form-control[disabled] {
        color: $gray-14;
        background: #fff;
      }
    }
  }

  .accept-terms {
    margin-bottom: 20px;

    .gn-checkbox {
      display: inline-block;
      vertical-align: top;
      padding: 0 !important;
    }

    label {
      max-width: 90%;
      padding-top: 2px;
    }
  }

}