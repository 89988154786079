$icon-font-path: '/fonts/';
$fonts-folder:'/fonts';

@import 'font_family';

//Fonts
$font-family-1: Arial, 'sans-serif';
$font-family-2: 'Open Sans', 'sans-serif';
$font-family-1-new-mark: 'MyriadPro Regular';
$font-family-2-book-new-mark: 'Bebas Neue Book';
$font-family-2-light-new-mark: 'Bebas Neue Light';
$font-family-2-regular-new-mark: 'Bebas Neue Regular';


//Colors
$gray-1: #6e6e6e;  // $cinzaEscuro
$gray-2: #f7f7f7;  // $cinzaBg
$gray-3: #d1d1d1;  // $cinzaMedio
$gray-4: #e7e7e7;  // $cinzaClaro
$gray-5: #6e6f71;
$gray-6: #222222;
$gray-7: #dddddd;
$gray-8: #696b70;
$gray-9: #4d4d4c;
$gray-10: #eeeeee;
$gray-11: #d7d7d7;
$gray-12: #f5f5f5;
$gray-13: #e9e9e9;
$gray-14: #9e9e9e;
$gray-15: #d1d2d4;
$gray-16: #a6a8ae;
$gray-17: #58585a;
$gray-18: #1f1f1f;
$gray-19: #c6c8ce;
$gray-20: #efefef;
$gray-21: #909090;
$gray-22: #fafafa;
$gray-23: #f1f1f1;
$gray-24: #b7b7b7;
$gray-25: #919191;
$gray-26: #e0e0e0;
$gray-27: #707070;
$gray-28: #ebebeb;

$green-1: #009615;  // $verde
$green-2: #069788;
$green-3: #718c00;
$green-4: #d6f2da;
$green-5: #65c072;

$blue-1: #00b4c5;  // $azulClaro
$blue-2: #008996;  // $azulEscuro
$blue-3: #29698e;
$blue-4: #c7faff;
$blue-5: #67e4ed;
$blue-6: #00bbce;

$red-1: #b50000;  // $vermelho
$red-2: #ffd5d6;

$yellow-1: #c4a600;  // $amareloEscuro
$yellow-2: #fcaf17;  // $amarelo

$orange-1: #f26522;  // $laranjaClaro
$orange-2: #c84d27;  // $laranjaEscuro
$orange-3: #f5871f;
$orange-4: #ea6e1c;
$orange-5: #f1641e;
$orange-6: #feefe8;
$orange-7: #ffecdb;
$orange-8: #ffa479;
$orange-9: #f37021;

$black-1: #231f20;  // $preto
$black-2: #000000;

$white-1: #ffffff;  // $branco


// Breaking points
$screen-xs: 480px;
$screen-xs-until: 479px;
$screen-sm: 768px;
$screen-sm-until: 767px;
$screen-md: 992px;
$screen-md-until: 991px;
$screen-lg: 1160px;
$screen-lg-until: 1200px;
$screen-xl: 1600px;
$screen-xl-until: 1599px;