.about {
  text-align: justify;
  .subtitle {
    color: $orange-1;
    text-align: left;
    font-family: $font-family-2;
    font-weight: normal;
    font-size: 30px;
  }

  ul.about-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border: 1px solid $gray-4;
      border-radius: 2px;
      padding: 10px 20px;
      margin-bottom: 10px;

      i {
        color: $green-1;
        font-size: 22px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }

  }

  .action {
    margin-top: 20px;
    text-align: center;
  }
}
