.transfer-form {
  padding: 0 40px;
  color: $gray-1;

  .gn-loading-brand {
    width: 100%;
    padding: 150px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;
  }

  .subtitle {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 30px;
  }

  label {
    font-weight: normal;
  }

  .with-space {
    @media all and(min-width: $screen-sm) {
      padding-right: 15px;
    }
  }

  .terms {
    font-size: 12px;
    margin: 0 0 15px;
  }

  .box {
    text-align: left;
    border: 1px solid $gray-4;
    border-radius: 2px;
    padding: 15px 20px;
    margin-bottom: 20px;

    .box-title {
      font-weight: bold;
      font-size: 16px;
    }

    .row {
      padding-left: 20px;
    }
  }

  .form-data {

    .form-rows {
      @media all and(min-width: $screen-md) {
        padding-left: 20px;
        padding-right: 100px;
        padding-top: 20px;
      }
    }

    .person-type {
      display: block;
    }

    .checkbox-inline {
      padding-left: 0;
      margin-left: 0;
    }

    .separator {
      line-height: 30px;
    }

    .gn-radio {
      display: inline-block;
    }

    .terms {
      text-align: left;
      border: 1px solid $gray-4;
      border-radius: 2px;
      padding: 15px 20px;
      font-size: 14px;

      .gn-checkbox {
        display: inline-block;
        vertical-align: top;
      }
      .term-label {
        width: 95%;
      }
    }

  }

  @import 'transfer-about';
}
