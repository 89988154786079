.contestation-detail {

  h5 {
    font-size: 17px;
    font-weight: 500;
  }

  .overview {
    display: flex;

    @media all and (max-width: $screen-md-until) {
      flex-direction: column;
      align-items: center;
    }

    .data {
      flex: 1;

      @media all and (max-width: $screen-md-until) {
        width: 100%;
      }

      .detail-item {

        div:first-child {
          font-size: 13px;
          margin-top: 25px;
          font-weight: bold;
        }

        div:last-child {
          font-size: 15px;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }

        i {
          font-size: 22px;
          vertical-align: middle;
          color: $yellow-2;
        }

        .contestation-status {
          i, span {
            display: inline-block;
            vertical-align: middle;
            font-weight: normal;
          }

          &.waiting {
            i,span {
              color: $yellow-1;
            }
          }

          &.finished {
            i,span {
              color: $green-1;
            }
          }
        }
      }
    }

    .agreement {
      max-width: 208px;
      border: 1px solid $gray-4;
      padding: 12px 24px;

      @media all and (max-width: $screen-md-until) {
        max-width: 410px;
        margin-top: 30px;
      }

      h3 {
        font-size: 16px;
        margin-top: 0;
      }

      p {
        font-size: 12px;
      }

      button {
        font-family: $font-family-2-regular-new-mark;
        font-size: 18px;
        white-space: normal;
      }
    }
  }

  .finisher {
    color: #fff;
    display: inline-block;
    padding: 2px 7px;
    border-radius: 4px;
    font-weight: normal !important;
    font-size: 12px !important;

    &.comprador {
      background: $blue-1;
    }

    &.vendedor {
      background: $green-1;
    }

    &.intermediador {
      background: $orange-1;
    }
  }

  .vendor {
    font-size: 16px;
    margin-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $gray-3;
    border-bottom: 1px solid $gray-3;

    h5, span {
      font-family: Arial, sans-serif !important;
    }

    .row {
      margin-top: 10px;
    }

    i {
      font-size: 20px;
      vertical-align: middle;
      color: $gray-3;
      margin-right: 5px;
    }

    .icon-inline {
      line-height: 22px;
      vertical-align: middle;
    }

    @media all and (max-width: $screen-md-until) {
      font-size: 14px;
    }
  }

  @media all and (max-width: $screen-md-until) {
    text-align: center;
  }

  @import '_contestation-negotiation';
}
