@import 'imports/core/mixins';
@import 'imports/core/variables';
@import '../bower_components/fontawesome/scss/font-awesome';
@import '../bower_components/bootstrap-sass/dist/stylesheets/bootstrap';
@import '../bower_components/icomoon-gn/style';
@import 'imports/core/common';
@import '../bower_components/ladda/dist/ladda-themeless.min.css';
@import 'imports/core/error';
@import 'imports/core/header';
@import 'imports/core/header-gateway';
@import 'imports/core/footer';
@import 'imports/core/password-tips-modal';


// Import modules css
@import 'imports/subuser/subuser-form';
@import 'imports/subuser/subuser-login';
@import 'imports/subuser/subuser-expired';

@import 'imports/transfer/transfer-form';
@import 'imports/transfer/transfer-expired';
@import 'imports/transfer/transfer-success';

@import 'imports/refund/refund';
@import 'imports/refund/refund-access';

@import 'imports/payer/payer';
@import 'imports/payer/subscription/_subscription-header';
@import 'imports/payer/subscription/subscription-cancel';
@import 'imports/payer/subscription/subscription-error';
@import 'imports/payer/subscription/subscription-confirmation';
@import 'imports/payer/subscription/subscription-success';
@import 'imports/payer/contestation/contestation';

@import 'imports/payer/account/account-sent';
@import 'imports/payer/account/account-code';
@import 'imports/payer/account/account-success';
@import 'imports/payer/account/account-error';
@import 'imports/payer/account/account-phone';

@import 'imports/auth/auth';

@import 'imports/auth/login/login';
@import 'imports/auth/login/geolocation';

@import 'imports/auth/redefine/redefine';
@import 'imports/auth/redefine/redefine-messages';
@import 'imports/auth/redefine/redefine-password';
@import 'imports/auth/profile/email-redefine';

@import 'imports/payer/duplicate/duplicate';

@import 'imports/notices/notices';
