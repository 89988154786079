$fonts-folder: "./fonts/" !default;

@font-face {
  font-family: "icomoon";
  src: url("#{$fonts-folder}/icomoon.eot?4x7osw");
  src: url("#{$fonts-folder}/icomoon.eot?4x7osw#iefix") format("embedded-opentype"),
    url("#{$fonts-folder}/icomoon.ttf?4x7osw") format("truetype"),
    url("#{$fonts-folder}/icomoon.woff?4x7osw") format("woff"),
    url("#{$fonts-folder}/icomoon.svg?4x7osw#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-rocket:before {
  content: "\e9d3";
}
.icon-in-process:before {
  content: "\e9d2";
}
.icon-palette:before {
  content: "\e9d0";
}
.icon-light-bulb:before {
  content: "\e9d1";
}
.icon-vertical-bar-chart:before {
  content: "\e9cf";
}
.icon-sort-alt:before {
  content: "\e9ce";
}
.icon-security:before {
  content: "\e9cc";
}
.icon-card-contactless:before {
  content: "\e9cd";
}
.icon-smiling:before {
  content: "\e9c7";
}
.icon-smile:before {
  content: "\e9c8";
}
.icon-sad:before {
  content: "\e9c9";
}
.icon-neutral:before {
  content: "\e9ca";
}
.icon-anguish:before {
  content: "\e9cb";
}
.icon-card-check:before {
  content: "\e9c5";
}
.icon-card-waiting:before {
  content: "\e9c6";
}
.icon-cellphone-o-2:before {
  content: "\e9bf";
}
.icon-emissao-cartao:before {
  content: "\e9c0";
}
.icon-grid-world-o:before {
  content: "\e9c1";
}
.icon-recarga-resgate-cartao-o:before {
  content: "\e9c2";
}
.icon-saque-o:before {
  content: "\e9c3";
}
.icon-tarifa-o:before {
  content: "\e9c4";
}
.icon-card-o:before {
  content: "\e9be";
}
.icon-download-files:before {
  content: "\e9bd";
}
.icon-card-machine-parcels:before {
  content: "\e9bc";
}
.icon-card-machine:before {
  content: "\e9bb";
}
.icon-scan-qrcode:before {
  content: "\e9ba";
}
.icon-cellphone-card-o:before {
  content: "\e9b9";
}
.icon-hand-coin-o:before {
  content: "\e9a4";
}
.icon-hanger:before {
  content: "\e9a5";
}
.icon-heart-graph-o:before {
  content: "\e9a6";
}
.icon-keyboard-2-o:before {
  content: "\e9a7";
}
.icon-percentage-o:before {
  content: "\e9a8";
}
.icon-perfume-o:before {
  content: "\e9a9";
}
.icon-pin-o:before {
  content: "\e9aa";
}
.icon-shopping-cart-o:before {
  content: "\e9ab";
}
.icon-truck-o:before {
  content: "\e9ac";
}
.icon-unlock-2:before {
  content: "\e9ad";
}
.icon-bags-o:before {
  content: "\e9ae";
}
.icon-box-o:before {
  content: "\e9af";
}
.icon-card-currency-o:before {
  content: "\e9b0";
}
.icon-cellphone-o:before {
  content: "\e9b1";
}
.icon-clock-at-3-o:before {
  content: "\e9b2";
}
.icon-coffee-o:before {
  content: "\e9b3";
}
.icon-credit-card-o:before {
  content: "\e9b4";
}
.icon-currency-o:before {
  content: "\e9b5";
}
.icon-gear-o:before {
  content: "\e9b6";
}
.icon-globe-bag-o:before {
  content: "\e9b7";
}
.icon-hand-card-o:before {
  content: "\e9b8";
}
.icon-check-light:before {
  content: "\e9a3";
}
.icon-clock-at-9:before {
  content: "\e9a0";
}
.icon-times-circle-light:before {
  content: "\e99c";
}
.icon-arrow-circle-left-2:before {
  content: "\e99d";
}
.icon-check-circle-light:before {
  content: "\e99e";
}
.icon-clock-at-4:before {
  content: "\e99f";
}
.icon-ban-circle:before {
  content: "\e9a1";
}
.icon-exclamation-circle-light:before {
  content: "\e9a2";
}
.icon-step-pix-5:before {
  content: "\e996";
}
.icon-step-pix-1:before {
  content: "\e997";
}
.icon-step-pix-4:before {
  content: "\e998";
}
.icon-step-pix-2:before {
  content: "\e999";
}
.icon-step-pix-3:before {
  content: "\e99a";
}
.icon-open-book:before {
  content: "\e99b";
}
.icon-hand-card:before {
  content: "\e992";
}
.icon-profile-times-circle:before {
  content: "\e993";
}
.icon-refresh-lock-card:before {
  content: "\e994";
}
.icon-calendar-money:before {
  content: "\e995";
}
.icon-check-circle-o-3:before {
  content: "\e991";
}
.icon-pix:before {
  content: "\e990";
}
.icon-billet-exclamation:before {
  content: "\e98f";
}
.icon-smartphone:before {
  content: "\e98e";
}
.icon-checkbox-unchecked-2:before {
  content: "\e98c";
}
.icon-cut-alt:before {
  content: "\e98d";
}
.icon-clock-alt-o:before {
  content: "\e98b";
}
.icon-dialog-gn:before {
  content: "\e98a";
}
.icon-chevron-right:before {
  content: "\e988";
}
.icon-chevron-left:before {
  content: "\e989";
}
.icon-link-plus:before {
  content: "\e986";
}
.icon-copy-o:before {
  content: "\e987";
}
.icon-hand-clicking-o:before {
  content: "\e984";
}
.icon-file-barcode-o:before {
  content: "\e985";
}
.icon-running-clock:before {
  content: "\e982";
}
.icon-locked-screen:before {
  content: "\e983";
}
.icon-no-money-o:before {
  content: "\e981";
}
.icon-download-alt:before {
  content: "\e97c";
}
.icon-print-alt:before {
  content: "\e97d";
}
.icon-share-alt-o:before {
  content: "\e97e";
}
.icon-coin:before {
  content: "\e97f";
}
.icon-copy-alt:before {
  content: "\e980";
}
.icon-info-circle-alt:before {
  content: "\e97b";
}
.icon-email-alt:before {
  content: "\e978";
}
.icon-sending-mail-alt:before {
  content: "\e97a";
}
.icon-google-authenticator-alt:before {
  content: "\e979";
}
.icon-hands-phone-alt:before {
  content: "\e976";
}
.icon-lock-2-alt:before {
  content: "\e977";
}
.icon-exclamation-alt:before {
  content: "\e975";
}
.icon-play_button:before {
  content: "\e973";
}
.icon-play_button_hover:before {
  content: "\e974";
}
.icon-backspace-o:before {
  content: "\e970";
}
.icon-check-circle-o-2:before {
  content: "\e971";
}
.icon-lock-2:before {
  content: "\e972";
}
.icon-clip:before {
  content: "\e96c";
}
.icon-dialog:before {
  content: "\e96d";
}
.icon-direct-download:before {
  content: "\e96e";
}
.icon-photo:before {
  content: "\e96f";
}
.icon-more:before {
  content: "\e96a";
}
.icon-replicar:before {
  content: "\e96b";
}
.icon-udemy:before {
  content: "\e969";
}
.icon-whatsapp-full:before {
  content: "\e968";
}
.icon-whatsapp:before {
  content: "\e967";
}
.icon-check-outline:before {
  content: "\e962";
}
.icon-anuidade-cartao:before {
  content: "\e961";
}
.icon-recarga-resgate-cartao:before {
  content: "\e963";
}
.icon-seta-codigo-ativacao:before {
  content: "\e964";
}
.icon-tarifa-emissao:before {
  content: "\e965";
}
.icon-tarifa-saques:before {
  content: "\e966";
}
.icon-no-money:before {
  content: "\e95d";
}
.icon-connection:before {
  content: "\e95e";
}
.icon-connection-outline:before {
  content: "\e95f";
}
.icon-no-founds:before {
  content: "\e960";
}
.icon-cartao-gn:before {
  content: "\e95c";
}
.icon-transferencia-programada-pendente:before {
  content: "\e95a";
}
.icon-transferencia-manual-pendente:before {
  content: "\e95b";
}
.icon-excel-preenchido:before {
  content: "\e959";
}
.icon-google-autenticator:before {
  content: "\e958";
}
.icon-clock4:before {
  content: "\e957";
}
.icon-icon-msg:before {
  content: "\e956";
}
.icon-cadeado-assinatura-eletronica:before {
  content: "\e954";
}
.icon-codigo-aleatorio:before {
  content: "\e955";
}
.icon-fechar:before {
  content: "\e952";
}
.icon-menu:before {
  content: "\e953";
}
.icon-icon-btn-entrar:before {
  content: "\e951";
}
.icon-gn-ico-new:before {
  content: "\e94e";
}
.icon-gn-shield-new:before {
  content: "\e94f";
}
.icon-conta-gn-new:before {
  content: "\e950";
}
.icon-conta-gn:before {
  content: "\e911";
}
.icon-gn-shield:before {
  content: "\e75b";
}
.icon-gn-ico:before {
  content: "\e74f";
}
.icon-emitir-boletos:before {
  content: "\e94d";
}
.icon-lab:before {
  content: "\e94c";
}
.icon-relatorio-gerado-sucesso:before {
  content: "\e94b";
}
.icon-cartao-assinatura:before {
  content: "\e949";
}
.icon-boleto-assinatura:before {
  content: "\e94a";
}
.icon-menu-parceria:before {
  content: "\e948";
}
.icon-tela-pagamento-01:before {
  content: "\e946";
}
.icon-tela-pagamento-01-o:before {
  content: "\e947";
}
.icon-grid-world:before {
  content: "\e945";
}
.icon-bubble-notification:before {
  content: "\e944";
}
.icon-phone-slash:before {
  content: "\e943";
}
.icon-documentacao-api:before {
  content: "\e942";
}
.icon-drawer-in:before {
  content: "\e93f";
}
.icon-drawer-out:before {
  content: "\e940";
}
.icon-coins:before {
  content: "\e941";
}
.icon-file-pdf-fill:before {
  content: "\e93e";
}
.icon-instagram:before {
  content: "\e93c";
}
.icon-linkedin:before {
  content: "\e93d";
}
.icon-users:before {
  content: "\e93a";
}
.icon-user-2:before {
  content: "\e93b";
}
.icon-valor-recebido:before {
  content: "\e915";
}
.icon-phone-wave:before {
  content: "\e939";
}
.icon-alarm2:before {
  content: "\e938";
}
.icon-dice:before {
  content: "\e937";
}
.icon-assinatura-eletronica:before {
  content: "\e936";
}
.icon-contestacao-data:before {
  content: "\e934";
}
.icon-contestacao-data-pagamento:before {
  content: "\e935";
}
.icon-attendant-male:before {
  content: "\e932";
}
.icon-attendant-female:before {
  content: "\e933";
}
.icon-stamp:before {
  content: "\e92e";
}
.icon-clipboard3-check:before {
  content: "\e92f";
}
.icon-clipboard3:before {
  content: "\e930";
}
.icon-search6:before {
  content: "\e931";
}
.icon-assinatura-concluida:before {
  content: "\e913";
}
.icon-notificacoes-cobrancas-geradas-com-erros:before {
  content: "\e928";
}
.icon-notificacoes-cobrancas-geradas:before {
  content: "\e92c";
}
.icon-notificacoes-dinheiro-check:before {
  content: "\e92d";
}
.icon-notificacoes-assinatura-inadimplente:before {
  content: "\e92a";
}
.icon-carne-proximo-conclusao:before {
  content: "\e92b";
}
.icon-notificacoes-dinheiro-error:before {
  content: "\e929";
}
.icon-recorrencia-assinatura:before {
  content: "\e912";
}
.icon-valor-pago:before {
  content: "\e914";
}
.icon-cobranca-inadimplente:before {
  content: "\e916";
}
.icon-cobranca-recebida:before {
  content: "\e917";
}
.icon-notificacoes-usuario-secundario-erro:before {
  content: "\e918";
}
.icon-notificacoes-usuario-secundario-ok:before {
  content: "\e919";
}
.icon-notificacoes-carne-concluido:before {
  content: "\e91a";
}
.icon-notificacoes-assinatura-nova:before {
  content: "\e91b";
}
.icon-notificacoes-assinatura-cancelamento:before {
  content: "\e91c";
}
.icon-notificacoes-malote:before {
  content: "\e91d";
}
.icon-notificacoes-aniversario:before {
  content: "\e91e";
}
.icon-notificacoes-saldo-bloqueio:before {
  content: "\e91f";
}
.icon-notificacoes-erro-transferencia:before {
  content: "\e920";
}
.icon-notificacoes-transferencia:before {
  content: "\e921";
}
.icon-notificacoes-comprovante:before {
  content: "\e922";
}
.icon-notificacoes-pagamento:before {
  content: "\e923";
}
.icon-notificacoes-aviso-gn:before {
  content: "\e924";
}
.icon-notificacoes-dinheiro-alerta:before {
  content: "\e925";
}
.icon-notificacoes-dinheiro-plus:before {
  content: "\e926";
}
.icon-cancel:before {
  content: "\e927";
}
.icon-bank-check:before {
  content: "\e909";
}
.icon-bank:before {
  content: "\e90a";
}
.icon-documents-check-01:before {
  content: "\e90b";
}
.icon-documents-clock-01:before {
  content: "\e90c";
}
.icon-documents-exclamation:before {
  content: "\e90d";
}
.icon-bank-check-01:before {
  content: "\e90e";
}
.icon-bank-clock:before {
  content: "\e90f";
}
.icon-bank-exclamation:before {
  content: "\e910";
}
.icon-attachment-mini:before {
  content: "\e908";
}
.icon-attachment:before {
  content: "\e907";
}
.icon-ticket-concluido-lido:before {
  content: "\e903";
}
.icon-ticket-novo:before {
  content: "\e904";
}
.icon-ticket-lido:before {
  content: "\e905";
}
.icon-filter2:before {
  content: "\e906";
}
.icon-checkbox-pseudochecked:before {
  content: "\e902";
}
.icon-boleto-telapagamento:before {
  content: "\e901";
}
.icon-rotate-left2:before {
  content: "\e717";
}
.icon-expand2:before {
  content: "\e750";
}
.icon-ban2:before {
  content: "\e751";
}
.icon-money:before {
  content: "\e752";
}
.icon-desconto:before {
  content: "\e900";
}
.icon-credit-card2:before {
  content: "\e800";
}
.icon-file-pdf:before {
  content: "\e753";
}
.icon-validaca-ok:before {
  content: "\e754";
}
.icon-falha-validacao:before {
  content: "\e755";
}
.icon-reduzir:before {
  content: "\e756";
}
.icon-bag:before {
  content: "\e757";
}
.icon-cart4:before {
  content: "\e758";
}
.icon-cart5:before {
  content: "\e759";
}
.icon-cash:before {
  content: "\e75a";
}
.icon-vcard:before {
  content: "\e75c";
}
.icon-bubble-dots3:before {
  content: "\e75d";
}
.icon-compass5:before {
  content: "\e74d";
}
.icon-spinner6:before {
  content: "\e74e";
}
.icon-lacamentos-futuros:before {
  content: "\e72f";
}
.icon-recebimentos-mes:before {
  content: "\e730";
}
.icon-obrigatorio:before {
  content: "\e731";
}
.icon-frete:before {
  content: "\e732";
}
.icon-cube2:before {
  content: "\e733";
}
.icon-taxa-gn:before {
  content: "\e734";
}
.icon-contestacao:before {
  content: "\e735";
}
.icon-contestacao-circle:before {
  content: "\e736";
}
.icon-trash-btn-gn:before {
  content: "\e737";
}
.icon-trash-gn:before {
  content: "\e738";
}
.icon-loading:before {
  content: "\e739";
}
.icon-key-gn:before {
  content: "\e73a";
}
.icon-backspace-cel:before {
  content: "\e73b";
}
.icon-config-frente-e-verso:before {
  content: "\e73c";
}
.icon-config-duas-folhas:before {
  content: "\e73d";
}
.icon-config-a5:before {
  content: "\e73e";
}
.icon-config-a4:before {
  content: "\e73f";
}
.icon-tela-pagamento:before {
  content: "\e740";
}
.icon-color-picker:before {
  content: "\e741";
}
.icon-seta-emitido-gn:before {
  content: "\e742";
}
.icon-question2:before {
  content: "\e743";
}
.icon-assinatura:before {
  content: "\e744";
}
.icon-mult-user:before {
  content: "\e745";
}
.icon-star2:before {
  content: "\e746";
}
.icon-clean:before {
  content: "\e747";
}
.icon-mail-tag:before {
  content: "\e748";
}
.icon-celular:before {
  content: "\e749";
}
.icon-correios-min:before {
  content: "\e74a";
}
.icon-globe2:before {
  content: "\e74b";
}
.icon-api:before {
  content: "\e600";
}
.icon-assinaturas:before {
  content: "\e601";
}
.icon-boleto:before {
  content: "\e602";
}
.icon-carnes:before {
  content: "\e603";
}
.icon-cartao:before {
  content: "\e72a";
}
.icon-cobrancas:before {
  content: "\e72b";
}
.icon-indicador:before {
  content: "\e72c";
}
.icon-meu-dinheiro:before {
  content: "\e72d";
}
.icon-align-center:before {
  content: "\e604";
}
.icon-align-justify:before {
  content: "\e605";
}
.icon-align-left:before {
  content: "\e606";
}
.icon-align-right:before {
  content: "\e607";
}
.icon-angle-down:before {
  content: "\e608";
}
.icon-angle-left:before {
  content: "\e609";
}
.icon-angle-right:before {
  content: "\e60a";
}
.icon-angle-up:before {
  content: "\e60b";
}
.icon-archive:before {
  content: "\e60c";
}
.icon-area-chart:before {
  content: "\e60d";
}
.icon-arrow-circle-down:before {
  content: "\e60e";
}
.icon-arrow-circle-left:before {
  content: "\e60f";
}
.icon-arrow-circle-o-down:before {
  content: "\e610";
}
.icon-arrow-circle-o-left:before {
  content: "\e611";
}
.icon-arrow-circle-o-right:before {
  content: "\e612";
}
.icon-arrow-circle-o-up:before {
  content: "\e613";
}
.icon-arrow-circle-right:before {
  content: "\e614";
}
.icon-arrow-circle-up:before {
  content: "\e615";
}
.icon-arrow-down:before {
  content: "\e616";
}
.icon-arrow-left:before {
  content: "\e617";
}
.icon-arrow-right:before {
  content: "\e618";
}
.icon-arrows:before {
  content: "\e619";
}
.icon-arrows-alt:before {
  content: "\e61a";
}
.icon-arrows-h:before {
  content: "\e61b";
}
.icon-arrows-v:before {
  content: "\e61c";
}
.icon-arrow-up:before {
  content: "\e61d";
}
.icon-asterisk:before {
  content: "\e61e";
}
.icon-backward:before {
  content: "\e61f";
}
.icon-ban:before {
  content: "\e620";
}
.icon-bar-chart:before {
  content: "\e621";
}
.icon-barcode:before {
  content: "\e622";
}
.icon-bars:before {
  content: "\e623";
}
.icon-bell:before {
  content: "\e624";
}
.icon-bell-o:before {
  content: "\e625";
}
.icon-bell-slash:before {
  content: "\e626";
}
.icon-bell-slash-o:before {
  content: "\e627";
}
.icon-binoculars:before {
  content: "\e628";
}
.icon-bitbucket:before {
  content: "\e629";
}
.icon-bitbucket-square:before {
  content: "\e62a";
}
.icon-book:before {
  content: "\e62b";
}
.icon-bookmark:before {
  content: "\e62c";
}
.icon-bookmark-o:before {
  content: "\e62d";
}
.icon-briefcase:before {
  content: "\e62e";
}
.icon-bug:before {
  content: "\e62f";
}
.icon-building:before {
  content: "\e630";
}
.icon-building-o:before {
  content: "\e631";
}
.icon-bullhorn:before {
  content: "\e632";
}
.icon-calculator:before {
  content: "\e633";
}
.icon-calendar:before {
  content: "\e634";
}
.icon-calendar-o:before {
  content: "\e635";
}
.icon-camera:before {
  content: "\e636";
}
.icon-camera-retro:before {
  content: "\e637";
}
.icon-caret-down:before {
  content: "\e638";
}
.icon-caret-left:before {
  content: "\e639";
}
.icon-caret-right:before {
  content: "\e63a";
}
.icon-caret-square-o-down:before {
  content: "\e63b";
}
.icon-caret-square-o-left:before {
  content: "\e63c";
}
.icon-caret-square-o-right:before {
  content: "\e63d";
}
.icon-caret-square-o-up:before {
  content: "\e63e";
}
.icon-caret-up:before {
  content: "\e63f";
}
.icon-certificate:before {
  content: "\e640";
}
.icon-chain:before {
  content: "\e641";
}
.icon-chain-broken:before {
  content: "\e642";
}
.icon-check:before {
  content: "\e643";
}
.icon-check-circle:before {
  content: "\e644";
}
.icon-check-circle-o:before {
  content: "\e645";
}
.icon-check-square:before {
  content: "\e646";
}
.icon-check-square-o:before {
  content: "\e647";
}
.icon-clipboard:before {
  content: "\e648";
}
.icon-clock-o:before {
  content: "\e649";
}
.icon-close:before {
  content: "\e64a";
}
.icon-cloud-download:before {
  content: "\e64b";
}
.icon-cloud-upload:before {
  content: "\e64c";
}
.icon-code:before {
  content: "\e64d";
}
.icon-code-fork:before {
  content: "\e64e";
}
.icon-cog:before {
  content: "\e64f";
}
.icon-cogs:before {
  content: "\e650";
}
.icon-columns:before {
  content: "\e651";
}
.icon-comment:before {
  content: "\e652";
}
.icon-comment-o:before {
  content: "\e653";
}
.icon-comments:before {
  content: "\e654";
}
.icon-comments-o:before {
  content: "\e655";
}
.icon-compress:before {
  content: "\e656";
}
.icon-copy:before {
  content: "\e657";
}
.icon-credit-card:before {
  content: "\e658";
}
.icon-crop:before {
  content: "\e659";
}
.icon-crosshairs:before {
  content: "\e65a";
}
.icon-cube:before {
  content: "\e65b";
}
.icon-cut:before {
  content: "\e65c";
}
.icon-dashboard:before {
  content: "\e65d";
}
.icon-database:before {
  content: "\e65e";
}
.icon-dedent:before {
  content: "\e65f";
}
.icon-desktop:before {
  content: "\e660";
}
.icon-download:before {
  content: "\e661";
}
.icon-edit:before {
  content: "\e662";
}
.icon-envelope:before {
  content: "\e663";
}
.icon-envelope-o:before {
  content: "\e664";
}
.icon-envelope-square:before {
  content: "\e665";
}
.icon-eraser:before {
  content: "\e666";
}
.icon-exchange:before {
  content: "\e667";
}
.icon-exclamation:before {
  content: "\e668";
}
.icon-exclamation-circle:before {
  content: "\e669";
}
.icon-exclamation-triangle:before {
  content: "\e66a";
}
.icon-expand:before {
  content: "\e66b";
}
.icon-external-link:before {
  content: "\e66c";
}
.icon-external-link-square:before {
  content: "\e66d";
}
.icon-eye:before {
  content: "\e66e";
}
.icon-eyedropper:before {
  content: "\e66f";
}
.icon-eye-slash:before {
  content: "\e670";
}
.icon-facebook:before {
  content: "\e671";
}
.icon-facebook-square:before {
  content: "\e672";
}
.icon-fast-backward:before {
  content: "\e673";
}
.icon-fast-forward:before {
  content: "\e674";
}
.icon-file:before {
  content: "\e675";
}
.icon-file-archive-o:before {
  content: "\e676";
}
.icon-file-code-o:before {
  content: "\e677";
}
.icon-file-excel-o:before {
  content: "\e678";
}
.icon-file-image-o:before {
  content: "\e679";
}
.icon-file-o:before {
  content: "\e67a";
}
.icon-file-pdf-o:before {
  content: "\e67b";
}
.icon-file-text:before {
  content: "\e67c";
}
.icon-file-text-o:before {
  content: "\e67d";
}
.icon-filter:before {
  content: "\e67e";
}
.icon-fire:before {
  content: "\e67f";
}
.icon-flag:before {
  content: "\e680";
}
.icon-floppy-o:before {
  content: "\e681";
}
.icon-folder:before {
  content: "\e682";
}
.icon-folder-o:before {
  content: "\e683";
}
.icon-folder-open:before {
  content: "\e684";
}
.icon-folder-open-o:before {
  content: "\e685";
}
.icon-font:before {
  content: "\e686";
}
.icon-forward:before {
  content: "\e687";
}
.icon-frown-o:before {
  content: "\e688";
}
.icon-gavel:before {
  content: "\e689";
}
.icon-gift:before {
  content: "\e68a";
}
.icon-globe:before {
  content: "\e68b";
}
.icon-google-plus:before {
  content: "\e68c";
}
.icon-google-plus-square:before {
  content: "\e68d";
}
.icon-group:before {
  content: "\e68e";
}
.icon-hand-o-down:before {
  content: "\e68f";
}
.icon-hand-o-left:before {
  content: "\e690";
}
.icon-hand-o-right:before {
  content: "\e691";
}
.icon-hand-o-up:before {
  content: "\e692";
}
.icon-hdd-o:before {
  content: "\e693";
}
.icon-headphones:before {
  content: "\e694";
}
.icon-heart:before {
  content: "\e695";
}
.icon-heart-o:before {
  content: "\e696";
}
.icon-home:before {
  content: "\e697";
}
.icon-image:before {
  content: "\e698";
}
.icon-inbox:before {
  content: "\e699";
}
.icon-indent:before {
  content: "\e69a";
}
.icon-info:before {
  content: "\e69b";
}
.icon-info-circle:before {
  content: "\e69c";
}
.icon-italic:before {
  content: "\e69d";
}
.icon-key:before {
  content: "\e69e";
}
.icon-keyboard-o:before {
  content: "\e69f";
}
.icon-level-down:before {
  content: "\e6a0";
}
.icon-level-up:before {
  content: "\e6a1";
}
.icon-lightbulb-o:before {
  content: "\e6a2";
}
.icon-line-chart:before {
  content: "\e6a3";
}
.icon-list:before {
  content: "\e6a4";
}
.icon-list-alt:before {
  content: "\e6a5";
}
.icon-list-ol:before {
  content: "\e6a6";
}
.icon-list-ul:before {
  content: "\e6a7";
}
.icon-location-arrow:before {
  content: "\e6a8";
}
.icon-lock:before {
  content: "\e6a9";
}
.icon-magic:before {
  content: "\e6aa";
}
.icon-magnet:before {
  content: "\e6ab";
}
.icon-mail-forward:before {
  content: "\e6ac";
}
.icon-mail-reply:before {
  content: "\e6ad";
}
.icon-mail-reply-all:before {
  content: "\e6ae";
}
.icon-map-marker:before {
  content: "\e6af";
}
.icon-meh-o:before {
  content: "\e6b0";
}
.icon-microphone:before {
  content: "\e6b1";
}
.icon-microphone-slash:before {
  content: "\e6b2";
}
.icon-minus:before {
  content: "\e6b3";
}
.icon-minus-circle:before {
  content: "\e6b4";
}
.icon-minus-square:before {
  content: "\e6b5";
}
.icon-minus-square-o:before {
  content: "\e6b6";
}
.icon-mobile:before {
  content: "\e6b7";
}
.icon-music:before {
  content: "\e6b8";
}
.icon-newspaper-o:before {
  content: "\e6b9";
}
.icon-paint-brush:before {
  content: "\e6ba";
}
.icon-paperclip:before {
  content: "\e6bb";
}
.icon-paper-plane:before {
  content: "\e6bc";
}
.icon-paper-plane-o:before {
  content: "\e6bd";
}
.icon-pause:before {
  content: "\e6be";
}
.icon-pencil:before {
  content: "\e6bf";
}
.icon-pencil-square:before {
  content: "\e6c0";
}
.icon-phone:before {
  content: "\e6c1";
}
.icon-phone-square:before {
  content: "\e6c2";
}
.icon-pie-chart:before {
  content: "\e6c3";
}
.icon-play:before {
  content: "\e6c4";
}
.icon-play-circle:before {
  content: "\e6c5";
}
.icon-play-circle-o:before {
  content: "\e6c6";
}
.icon-plug:before {
  content: "\e6c7";
}
.icon-plus:before {
  content: "\e6c8";
}
.icon-plus-circle:before {
  content: "\e6c9";
}
.icon-plus-square:before {
  content: "\e6ca";
}
.icon-plus-square-o:before {
  content: "\e6cb";
}
.icon-power-off:before {
  content: "\e6cc";
}
.icon-print:before {
  content: "\e6cd";
}
.icon-puzzle-piece:before {
  content: "\e6ce";
}
.icon-qrcode:before {
  content: "\e6cf";
}
.icon-question:before {
  content: "\e6d0";
}
.icon-question-circle:before {
  content: "\e6d1";
}
.icon-quote-left:before {
  content: "\e6d2";
}
.icon-quote-right:before {
  content: "\e6d3";
}
.icon-random:before {
  content: "\e6d4";
}
.icon-recycle:before {
  content: "\e6d5";
}
.icon-refresh:before {
  content: "\e6d6";
}
.icon-repeat:before {
  content: "\e6d7";
}
.icon-retweet:before {
  content: "\e6d8";
}
.icon-rotate-left:before {
  content: "\e6d9";
}
.icon-search:before {
  content: "\e6da";
}
.icon-search-minus:before {
  content: "\e6db";
}
.icon-search-plus:before {
  content: "\e6dc";
}
.icon-share-alt:before {
  content: "\e6dd";
}
.icon-share-alt-square:before {
  content: "\e6de";
}
.icon-share-square:before {
  content: "\e6df";
}
.icon-share-square-o:before {
  content: "\e6e0";
}
.icon-shield:before {
  content: "\e6e1";
}
.icon-shopping-cart:before {
  content: "\e6e2";
}
.icon-signal:before {
  content: "\e6e3";
}
.icon-sign-in:before {
  content: "\e6e4";
}
.icon-sign-out:before {
  content: "\e6e5";
}
.icon-sitemap:before {
  content: "\e6e6";
}
.icon-skype:before {
  content: "\e6e7";
}
.icon-slack:before {
  content: "\e6e8";
}
.icon-sliders:before {
  content: "\e6e9";
}
.icon-smile-o:before {
  content: "\e6ea";
}
.icon-sort:before {
  content: "\e6eb";
}
.icon-sort-alpha-asc:before {
  content: "\e6ec";
}
.icon-sort-alpha-desc:before {
  content: "\e6ed";
}
.icon-sort-amount-asc:before {
  content: "\e6ee";
}
.icon-sort-amount-desc:before {
  content: "\e6ef";
}
.icon-sort-asc:before {
  content: "\e6f0";
}
.icon-sort-desc:before {
  content: "\e6f1";
}
.icon-sort-numeric-asc:before {
  content: "\e6f2";
}
.icon-star:before {
  content: "\e6f3";
}
.icon-star-half:before {
  content: "\e6f4";
}
.icon-star-half-empty:before {
  content: "\e6f5";
}
.icon-star-o:before {
  content: "\e6f6";
}
.icon-step-backward:before {
  content: "\e6f7";
}
.icon-step-forward:before {
  content: "\e6f8";
}
.icon-stop:before {
  content: "\e6f9";
}
.icon-subscript:before {
  content: "\e6fa";
}
.icon-suitcase:before {
  content: "\e6fb";
}
.icon-sun-o:before {
  content: "\e6fc";
}
.icon-superscript:before {
  content: "\e6fd";
}
.icon-table:before {
  content: "\e6fe";
}
.icon-tablet:before {
  content: "\e6ff";
}
.icon-tag:before {
  content: "\e700";
}
.icon-tags:before {
  content: "\e701";
}
.icon-tasks:before {
  content: "\e702";
}
.icon-terminal:before {
  content: "\e703";
}
.icon-text-height:before {
  content: "\e704";
}
.icon-text-width:before {
  content: "\e705";
}
.icon-th:before {
  content: "\e706";
}
.icon-th-large:before {
  content: "\e707";
}
.icon-th-list:before {
  content: "\e708";
}
.icon-thumbs-down:before {
  content: "\e709";
}
.icon-thumbs-o-down:before {
  content: "\e70a";
}
.icon-thumbs-o-up:before {
  content: "\e70b";
}
.icon-thumbs-up:before {
  content: "\e70c";
}
.icon-thumb-tack:before {
  content: "\e70d";
}
.icon-ticket:before {
  content: "\e70e";
}
.icon-times-circle:before {
  content: "\e70f";
}
.icon-times-circle-o:before {
  content: "\e710";
}
.icon-tint:before {
  content: "\e711";
}
.icon-toggle-off:before {
  content: "\e712";
}
.icon-toggle-on:before {
  content: "\e713";
}
.icon-trash:before {
  content: "\e714";
}
.icon-trash-o:before {
  content: "\e715";
}
.icon-trophy:before {
  content: "\e716";
}
.icon-tty:before {
  content: "\e718";
}
.icon-twitter:before {
  content: "\e719";
}
.icon-twitter-square:before {
  content: "\e71a";
}
.icon-umbrella:before {
  content: "\e71b";
}
.icon-underline:before {
  content: "\e71c";
}
.icon-unlock:before {
  content: "\e71d";
}
.icon-unlock-alt:before {
  content: "\e71e";
}
.icon-upload:before {
  content: "\e71f";
}
.icon-user:before {
  content: "\e720";
}
.icon-volume-down:before {
  content: "\e721";
}
.icon-volume-off:before {
  content: "\e722";
}
.icon-volume-up:before {
  content: "\e723";
}
.icon-wifi:before {
  content: "\e724";
}
.icon-windows:before {
  content: "\e725";
}
.icon-wrench:before {
  content: "\e726";
}
.icon-youtube:before {
  content: "\e727";
}
.icon-youtube-play:before {
  content: "\e728";
}
.icon-youtube-square:before {
  content: "\e729";
}
.icon-time-square:before {
  content: "\e74c";
}
