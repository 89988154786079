.password-tips {
  &.modal-backdrop {
    background: #000;
  }

  .modal-header {
    padding: 20px 10px 20px 20px;

    .icon-close {
      color: #F37021;
    }

    h3 {
      color: #6E6F71;
      font-size: 28px;
      font-weight: 100;
    }
  }

  .modal-body {
    position: relative;
    padding: 16px 16px 80px 16px;
    .list-group {
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      padding: 10px;
      color: #58595B;
    
      ul {
        list-style: none;
        font-family: Arial;
        margin-top: 14px;
      }
    
      li {
        padding-bottom: 8px;
        margin-left: -30px;
      }
    
      ul li:before {
        content: '✔';
        color: #F37021;
      }
    }

    .btn {
      margin-top: 20px;
      float: right;
      padding: 8px 12px;
    }
  }

}