.subscription-header {
  .title {
    color: $orange-1;
    font-family: $font-family-2;

    i {
      cursor: pointer;
      color: $blue-1;
      font-weight: normal;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      display: inline-block;
    }
  }

  .subtitle {
    font-size: 28px;
    color: $orange-1;
    font-family: $font-family-2;
    margin: 20px 0 10px;
  }
}
