.redefine-sent, .redefine-success, .redefine-error {
  max-width: 600px;
  margin: 0 auto;

  @media all and(max-width: 768px){
    max-width: 400px;
  }


  h2 {
    color: $green-1;
    font-size: 16px;
    font-weight: 600;

    i {
      font-size: 25px;
      vertical-align: middle;
    }
  }

  .success-info {
    line-height: 5ex;
    margin: 0 auto;
    display: table;
    color: #6e6f71;
    max-width: 600px;
    width: 100%;
    font-family: "Open Sans Light";
    font-style: normal;
    font-variant: normal;
    padding-left: 14px;
  }

  .success-icon {
    margin-right: -4px;
    width: 30px;

    @media all and(max-width: 768px){
      width: 18px;
    }

    @media all and(min-width: 376px){
      margin-left: 20px;
    }
  }

  .success-text {
    font-size: 24px;
    margin: 0px;
    font-weight: 1;
    @media all and(max-width: 768px){
      font-size: 14px;
    }
  }


  .failed-box {
    border: 2px solid #e7e7e7;
    padding: 24px;
    margin: 40px auto;
    max-width: 600px;
    width: 100%;
    font-family: "Arial";
    font-style: normal;
    font-variant: normal;

    .info {
      color: #6E6F71;
      line-height: 3ex;
      max-width: 250px;
      margin-left: 6px;
      
      @-moz-document url-prefix() {
        margin-left: 8px;
      }

      .failed-icon {
        padding-top: 4px;
        margin-bottom: 2px;
      }
      .failed-text {
        font-family: "Arial";
        font-weight: bold;
        font-size: 14px;
      }
      @media all and(max-width: 768px){
        margin-left: 0;
      }
    }
  
    p.text {
      margin: 0 auto;
      display: table;
      font-family: "Arial";
      -webkit-padding-start: 0;
      -moz-padding-start: 0;
      max-width: 600px;
      color: #6E6F71;
      font-size: 14px;
      @media all and(max-width: 768px){
        -webkit-padding-start: 41px;
        -moz-padding-start: 35px;
      }
    }
  }

  p {
    text-align: left;
  }

  .btn {
    margin-top: 30px;
  }

}

.btn-try-again {
  padding: 8px 12px;
  margin-top: 10px !important;
}

.group {
  margin: 16px auto 0 auto;
  border: 1px solid #D1D2D4;
  border-radius: 2px;
  padding: 2px;
  max-width: 320px ;
  color: #58595B;

  p {
    font-weight: bold;
    color: #414042;
    margin-left: 16px;
    margin-top: 18px;
  }

  .download-app {
    list-style: none;
    align-items: center;
    display: flex;
    margin: 20px 4%;
    flex-direction: row;
  
    @media screen and (max-width: 400px) {
      margin-right: 5rem;
      margin: 20px auto;
    }
  
    @media screen and (max-width: 350px) {
      margin-right: 5rem;
      flex-direction: column;

      li {
        margin-left: -20px;
      }
    }
  }
  
  .download-app li:first-child {
    margin-right: 5rem;
    margin-left: -38px;

    @media screen and (max-width: 400px) {
      margin-left: -26px;
    }
  
    @media screen and (max-width: 350px) {
      margin-right: 0px;
      margin-left: -20px;
    }
  }
  
  .google-play {
    width: 10rem;
    height: 5.3rem;
    display: inline-block;
    background-size: contain;
    -webkit-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    -moz-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    -ms-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    -o-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    transition: all .3s cubic-bezier(.42, 0, .58, 1);
    transition-delay: 0s
  }
  
  .google-play span {
    display: inline-block;
  }
  
  .app-store {
    width: 10rem;
    height: 5.3rem;
    display: inline-block;
    background-size: contain;
    -webkit-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    -moz-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    -ms-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    -o-transition: all .3s cubic-bezier(.42, 0, .58, 1);
    transition: all .3s cubic-bezier(.42, 0, .58, 1);
    transition-delay: 0s
  }
  
  .app-store span {
    display: inline-block;
  }
}
