.subuser-expired {
  max-width: 650px;
  color: $gray-1;

  .gn-loading-brand {
    width: 100%;
    padding: 150px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    &:h4 {
      font-size: 22px;
    }
  }

  .subtitle {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 30px 0;
  }

  ul {
    color: $blue-1;
  }

}
