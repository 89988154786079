.transfer-expired {
  color: $gray-1;
  padding: 0 40px;

  .gn-loading-brand {
    width: 100%;
    padding: 150px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    &:h4 {
      font-size: 22px;
    }
  }

  .subtitle {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 30px 0;
  }

  .util {
    color: $blue-1;
  }

  @import 'transfer-about';

}
