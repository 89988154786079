.subuser-login {
  max-width: 650px;
  color: $gray-1;

  .gn-loading-brand {
    width: 100%;
    padding: 150px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;
  }

  .subtitle {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0;
  }

  .masked-email {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 25px;
    background: $gray-4;
    border-radius: 2px;
    display: inline-block;
  }

  .terms {
    font-size: 12px;
    margin: 0 0 15px 0;
  }

  .form-login {
    margin-top: 25px;

    .box {
      margin: 10px auto;
      font-size: 0;
      max-width: 350px;
      border-radius: 2px;
      border: 1px solid $gray-4;
      padding: 15px;
      position: relative;

      .box-icon {
        position: absolute;
        top: 45px;
        left: -78px;
        background: $gray-14;
        color: $gray-4;
        padding: 15px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;

        @media all and (max-width: $screen-sm-until) {
          padding: 10px;
          left: -55px;
        }

        @media all and (max-width: $screen-xs-until) {
          display: none;
        }

        .icon {
          font-size: 60px;

          @media all and (max-width: $screen-sm-until) {
            font-size: 45px;
          }
        }
      }

      label {
        font-weight: normal;
        font-size: 14px;
      }

      .without-margin {
        margin: 0;
      }

      .recover-password {
        font-size: 13px;
        text-align: right;
        margin: 5px 0 10px 0;
      }
    }

    h4 {
      font-size: 22px;
      margin: 0 0 15px 0;
    }
  }
}
