.login {
  margin: 0 auto;
  text-align: left;
  max-width: 326px;
  position: relative;

  .resend-email{
    margin: 30px 0;
  }
  
  .links {
    margin-top: 80px;

    a {
      margin-top: 20px;
      display: block;
    }
  }

  .help-links {
    font-size: 16px;
  }
}

.mobile-apps	{

  .text {
    color: $black-1;
  }

  .install-apps {
    text-align: center;

    .text {
      color: $black-1;
    }

    .google-play {
      height: 40px;
    }

    .app-store {
      height: 40px;
    }
  }

  .modal-footer {
    text-align: center;
  }
}
