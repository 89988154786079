.billet {
  .container {

    .billet-container {
      .container {
        width: auto;

        @media screen and (max-width: 768px) {
          margin: 4.5rem 4rem 0 4rem;
        }

        .timeline-como-funciona {
    
          .list-steps {
            list-style: none;
            position: relative;
            width: 100%;
            padding: 84px 0px 0 0px;
            @include flex ($justify: center, $align: flex-start, $direction: column, $wrap: wrap);

            @media screen and (min-width: 1160px) {
              padding: 84px 0 0 25px;
            }

            @media screen and (min-width: 1160px) {
              padding: 84px 0 0 40px;
            }

            @media screen and (min-width: 1600px) {
              padding: 84px 60px 0 40px;
            }
          }
    
          li {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 80px;
            padding-right: 0;
  
            .text-2 {
              margin: 0 0 0 34px;
              text-align: left;
              max-width: calc(100% - 12rem);

              @media screen and (max-width: 767px) {
                max-width: 79%;
                margin-left: 20px;
              }
  
              color: $gray-5;
              font-family: $font-family-1-new-mark;
              font-size: 2rem;
              font-weight: 300;
              line-height: 2.8rem;
              letter-spacing: 0px;
  
              strong {
                font-weight: 700;
              }
            }
    
            .step {
              @media screen and (max-width:767px){
                width: 4rem;
                height: 4rem;
              }

              width: 7rem;
              height: 7rem;
              position: relative;
              margin-bottom: 0;
              @include flex ($justify: center, $align: center, $direction: row, $wrap: wrap);

              &:after {
                content: '';
                width: 100%;
                height: 100%;
                border-radius: 50%;
                @include absolute ($top: 50%, $right: auto, $bottom: auto, $left: 50%);
                background-color: $orange-9;
                transform: translate(-50%, -50%);
                z-index: 2;
              }

              &:before {
                content: '';
                border-radius: 50%;
                @include absolute ($top: 50%, $right: auto, $bottom: auto, $left: 50%);
                background-color: $orange-9;
                transform: translate(-50%, -50%);
                z-index: 1;
              }

              span {    
                @media screen and (max-width:767px) {
                 font-size: 2.1rem;
                }

                @include font($tamanho: 4.1rem, $peso: 400, $line-height: 1em, $letter-spacing: 0);
                color: $white-1;
                z-index: 5;
              }
            }
          }
        }
      }
    }

    .info-billet {
      position: relative;

      @import '_billet_bullets';

      img {
        width: 100%;
        display: none;

        @media screen and (min-width: 768px) {
          display: flex;
          position: relative;
        }

        @media screen and (min-width: 1160px) {
          top: -180px;
          margin-bottom: -180px;
        }

        @media screen and (min-width: 1600px) {
          top: -250px;
          margin-bottom: -250px;
          max-height: 1000px;
        }

        .border-img {
          background-color: $orange-9;
        }
      }

      .img-representation {
        display: none;

        @media screen and (min-width: 768px) {
          display: flex;
          margin: 5px;
          font-size: 1.2rem;
          font-family: $font-family-1-new-mark;
        }
      }

      .btn-go-top {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin: 40px 0;
        justify-content: flex-end;

        .btn-up {
          width: 48px;
          height: 48px;
          position: relative;
          margin-bottom: 0;
          @include flex ($justify: center, $align: center, $direction: row, $wrap: wrap);

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 50%;
            @include absolute ($top: 50%, $right: auto, $bottom: auto, $left: 50%);
            background-color: $blue-6;
            transform: translate(-50%, -50%);
            z-index: 2;
          }

          &:before {
            content: '';
            border-radius: 50%;
            @include absolute ($top: 50%, $right: auto, $bottom: auto, $left: 50%);
            background-color: $blue-6;
            transform: translate(-50%, -50%);
            z-index: 1;
          }

          span {
            @include font($tamanho: 2.5rem, $peso: 400, $line-height: 1em, $letter-spacing: 0);
            color: $white-1;
            z-index: 5;
          }
        }

        p {
          margin: 0 0 0 10px;
          color: #00bbce;
          font-family: $font-family-1-new-mark;
          font-size: 2rem;
          font-weight: 400;
          letter-spacing: 0.24px;
          line-height: 14px;
        }
      }
    }
  }
}