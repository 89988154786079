.contestation-access {
  .warning-message {
    margin-bottom: 14px;
  }

  .form-group {
    margin-bottom: 15px;
    width: 300px;

    input {
      text-align: center;
      height: 45px;
    }
  }

  .captcha {
    width: 300px;
    margin: 20px 0;
  }
}
