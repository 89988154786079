footer{
  z-index: 150;
  background: url(/img/2-layers.png);
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 2%;
  border-top: 10px solid #e4e4e4;

  .center-content {
    width: $screen-lg-until;
    margin: 0 auto;
  }

  section.base-footer{
    clear: both;
  }

  nav#navegacaoRodape {
    padding: 20px 0 0 0;
    clear: both;
    display: flex;

    > div {
      float: left;
      width: 18%;
      margin: 0 1%;

      h2 {
        font-family: $font-family-2-book-new-mark;
        text-transform: uppercase;
        color: #f37021;
        font-size: 1.37em;
        border-bottom: 4px solid #e4e4e4;
        padding: 8px 0;
        margin: 0 0 12px 0;
      }

      ul{
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          margin: 2px 0 2px 12px;

          a {
            display: block;
            padding: 2px;
            font-size: 0.87em;
            color: #00bbce;
            text-decoration: none;

            &:focus, &:hover {
              color: #018896;
            }
          }
        }
      }

      &.informacoes {
        width: 40%;
        div {
          width: 50%;
          float: left;
        }
      }

      &.social-media{
        a{
          display: inline-block;
          width: 26px;
          height: 26px;
          overflow: hidden;
          position: relative;
          line-height: 10em;
          border: 1px solid #00b4c5;
          margin-right: 2px;

          &:before {
            font-family: 'icomoon';
            display: block;
            position: absolute;
            line-height: 19px;
          }

          &:hover, &:focus {
            background: #00bbce;
            color: #ffffff;
          }
        }
      }
    }
  }

  section.copyright {
    margin-left: auto;
    margin-right: auto;
    clear: both;
    margin-top: 150px;

    p{
      margin: 0;
    }

    .direitos {
      text-align: right;
      font-size: 0.75em;
      line-height: 1.3em;

      .dados_gn {
        font-size: .9em;
        line-height: 1.35em;
      }
    }
  }

  .facebook:before {
    content: "\e671";
    bottom: 1px;
    right: 8px;
  }

  .linkedin:before {
    content: "\e93d";
    bottom: 1px;
    right: 3px;
  }

  .twitter:before {
    content: "\e719";
    bottom: 1px;
    right: 5px;
  }

  .instagram:before {
    content: "\e93c";
    bottom: 1px;
    right: 4px;
  }

  .googleplus:before {
    content: "\e68c";
    bottom: 2px;
    right: 5px;
    top: 4px;
  }

  @media all and (max-width: 1800px) {
    section.copyright {
      margin-top: 10%;
    }
  }

  @media all and (max-width: 1700px) {
    section.copyright {
      margin-top: 8%;
    }
  }

  @media all and (max-width: $screen-xl-until) {
    section.copyright {
      width: 85%;
      margin-top: 6%;
    }
  }

  @media all and (max-width: 1400px) {
    section.copyright {
      width: 80%;
      margin-top: 3%;
    }
  }

  @media all and (max-width: 1300px) {
    background-size: 100%;
    background-size: 103%;
    background-position-y: 38%;
    height: 220px;

    .center-content{
      width: auto;
      margin: 0 10px;
      &:before {
        display: none;
      }
    }

    section.base-footer{
      clear: both;
      width: 50%;
      float: left;
    }

    nav#navegacaoRodape{
      div:not(#social-media){
        display: none;
      }

      div#social-media {
        margin-left: 13%;
        float: left;
        width: 180px;
      }

      padding: 20px 0 0 0;
      clear: both;
      display: flex;
    }

    section.copyright{
      float: left;
      display: inline;
      width: 40%;
      margin: 0;
      clear: none;
      margin-top: 3%;
      margin-right: -5%;
    }
  }


  @media all and (max-width: $screen-md-until) {
    nav#navegacaoRodape{
      div#social-media {
        width: 186px;
        margin-left: 10%;
      }
    }
  }

  @media all and (max-width: $screen-sm-until) {
    background: url(/img/2-layers-tablet.png) center center no-repeat;
    background-size: 100%;
    background-position-y: 39%;
    height: 225px;

    section.base-footer{
      clear: both;
      width: 40%;
      float: left;
    }

    nav#navegacaoRodape{
      padding: 10px;

      div#social-media {
        width: 145px;
        margin-left: 5%;

        h2{
          font-size: 1.25em;
          padding: 3px 0;
          margin: 0 0 6px;
        }

        a{
          width: 22px;
          height: 22px;
        }

        a:before{
          font-size: 13px;
          line-height: 15px;
        }
      }
    }

    section.copyright{
      float: left;
      display: inline;
      width: 54%;
    }
  }

  @media all and (max-width: 560px) {
    height: 186px;

    section.base-footer{
      width: 100%;
    }

    nav#navegacaoRodape{
      padding: 10px;

      div#social-media {
        width: 32%;

        h2{
          font-size: 1.25em;
          padding: 3px 0;
          margin: 0 0 6px;
        }

        a{
          width: 22px;
          height: 22px;
        }

        a:before{
          font-size: 13px;
          line-height: 15px;
        }
      }
    }

    section.copyright{
      width: 88%;
      margin-top: 5%;
    }
  }

  @media all and (max-width: $screen-xs-until){

    nav#navegacaoRodape{
      div#social-media {
        width: 155px;
      }
    }

    section.copyright{
      margin-top: 3%;

      .direitos {
        font-size: 10px;
      }
    }
  }


}
