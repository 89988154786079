.redefine {
  margin: 0 auto;
  text-align: left;
  max-width: 400px;
  position: relative;

  form {
    width: 300px;
    margin: 0 auto 60px auto;
    text-align: center;
  }

}