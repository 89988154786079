body {
  width: 100%;
  background: white;
}

.content {
  margin-bottom: 25px;
}

[ng-message],
ng-message-default,
ng-message {
  display: inline-block;
  width: 100%;
  text-align: right;
  font-size: 11px;
  color: $red-1;
}

.main-section {
  position: relative;
  max-width: $screen-lg;
  min-width: 320px;
  margin: 0 auto;
  font-family: $font-family-1;
  color: $gray-5;

  &.loading {
    background-color: white;
  }
}

.gn-radio {
  padding-top: 5px !important;

  .radio {
    display: inline-block;
    cursor: pointer;
    margin: 0 5px;
  }

  input[type=radio] {
    display: none;
  }

  :before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: white;
    color: white;
    text-align: center;
    box-shadow: 0 0 0 2px white, 0 0 0 3px black;
    border-radius: 50%;
  }
  input[type=radio]:checked + .radio:before {
    background: $orange-1;
  }
  input[type=radio]:checked:disabled + .radio:before {
    background: $gray-1;
  }
}

.gn-checkbox {
  padding-top: 5px !important;

  .check-all {
    margin-top: 2px !important;
  }

  .checkbox {
    display: inline-block;
    cursor: pointer;
    margin: 0 5px;
  }

  input[type=checkbox] {
    display: none;
  }

  :before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: white;
    color: white;
    text-align: center;
    box-shadow: 0 0 0 2px white, 0 0 0 3px black;
  }
  input[type=checkbox]:checked + .checkbox:before {
    background: $orange-1;
  }
}

textarea {
  resize: none;
}

.text-left {
  text-align: left;
}

.m-top-30 {
  margin-top: 30px !important;
}

.m-bottom-45 {
  margin-bottom: 45px !important;
}

.gn-password-strength {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  vertical-align: 2px;
  width: 100%;

  .point {
    background: #DDD;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 5px;
    width: 24%;
  }
  .point:last-child {
    margin: 0 !important;
  }
}

.gn-password-hint {
  display: inline-block;
  width: 100%;
  text-align: right;
  font-size: 11px;
  color: #b50000;
}

.modal-header {
  .icon-close {
    color: $blue-1;
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 30px;
    &:hover {
      cursor: pointer;
    }
  }
}

.modal-footer {
  button {
    font-family: $font-family-2-regular-new-mark;
    font-size: 18px;
    border-radius: 0;
  }

  &.warning {
    background: $gray-12;
    text-align: left;
    color: $yellow-2;
    padding-left: 54px;
    padding-top: 14px;
    padding-bottom: 14px;
    position: relative;

    &:before {
      content:'\e66a';
      font-family: 'icomoon';
      position: absolute;
      top: 18px;
      left: 20px;
      font-size: 24px;
    }
  }
}


.preload {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: rgb(252, 146, 96);
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;

  &.fade-out {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.3s, opacity 0.3s linear;
    -moz-transition: visibility 0s 0.3s, opacity 0.3s linear;
    -ms-transition: visibility 0s 0.3s, opacity 0.3s linear;
    -o-transition: visibility 0s 0.3s, opacity 0.3s linear;
  }
}

.pre-loader {
  text-align: center;
  margin: 20px 0;
  display: inline-block;

  @mixin animation($name, $duration, $timing: linear) {
    display: inline-block;
    -moz-animation-name: $name;
    -moz-animation-duration: $duration;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: $timing;

    -o-animation-name: $name;
    -o-animation-duration: $duration;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: $timing;

    animation-name: $name;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: $timing;

    -webkit-animation-name: $name;
    -webkit-animation-duration: $duration;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: $timing;

    -ms-animation-name: $name;
    -ms-animation-duration: $duration;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: $timing;
  }

  text-align: center;
  margin: 20px 0;
  display: inline-block;

  .icon {
    display: inline-block;
    font-family: "icomoon";
    color: #f26522;
    line-height: 1;
    position: relative;
    font-size: 100%;
  }

  .icon {
    &:before {
      content: "\e9d6";
      font-size: 100px;
      position: absolute;
      top: 50px;
      left: 45px;
      color: white;
    }

    &:after {
      content: "\e739";
      font-size: 200px;
      color: white;
      @include animation(spin, 2s);
    }
  }
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
