// Alerts

@mixin alert-variant($background, $border, $text-color, $icon) {
  color: $text-color;

  &:before {
    background: $background;
    content: $icon;
  }

  &:after {
    background: $background;
  }

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($text-color, 10%);
  }

  .close {
    color: $background;

    &:focus {
      color: $background;
    }

    &:hover {
      color: darken($background, 10%);
    }
  }

  .icon-close {
    color: $background;

    &:hover {
      color: darken($background, 10%);
    }
  }
}
