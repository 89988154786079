.subscription-success {
  max-width: $screen-sm-until;
  color: $gray-1;

  h3 {
    color: $green-1;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin: 0;

    &.bold {
      margin-bottom: 5px;
    }
  }

  .box {
    border: 1px solid $gray-4;
    border-radius: 2px;
    padding: 10px;
    margin: 15px 0;
    font-size: 13px;
  }

  .bold {
    font-weight: bold;
  }

  h4 {
    margin-top: 0;
    font-size: 16px;
  }
}
