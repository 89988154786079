.negotiation {
  color: $gray-5;
  min-height: 500px !important;

  .attach-types {
    margin-top: 5px;
    font-size: 10px;
  }

  .box {
    border: 1px solid $gray-4;
    border-radius: 2px;
    padding: 10px 16px;
    margin-bottom: 10px;
    max-height: 500px;
    overflow-y: scroll;
  }

  h6 {
    font-size: 14px;
  }

  .messages {
    font-size: 0;

    .data-message {
      margin: 10px 0 5px 0;

      .icon {
        font-size: 35px;
        display: inline-block;
        vertical-align: middle;
      }

      .inline {
        vertical-align: middle;
        display: inline-block;
        width: 80%;
        margin: 0 10px;

        .author {
          color: $orange-3;
          font-family: $font-family-2;
          font-weight: 300;
          font-size: 16px;
        }

        .date {
          font-size: 14px;
        }
      }
    }

    .content-message {
      font-size: 14px;
      width: 90%;
      text-align: left;
      display: inline-block;
      padding: 5px;
      border-radius: 3px;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        top: -7px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid;
      }

      &:after {
        position: absolute;
        content: "";
        top: -5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid;
      }
    }

    .file {
      width: 88%;
      padding: 2px 4px 3px 4px;
      font-size: 11px;
      text-align: left;
      background: $gray-4;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      display: inline-block;
      margin: 0 1%;

      .icon {
        font-size: 15px;
        color: $gray-24;
        vertical-align: middle;
      }

      a {
        vertical-align: middle;
      }
    }

    .buyer-message {
      margin-top: 20px;
      text-align: right;

      .content-message {
        background: $green-4;
        border: 1px solid $green-5;

        &:before {
          right: 6px;
          border-bottom-color: $green-5;
        }

        &:after {
          right: 8px;
          border-bottom-color: $green-4;
        }
      }
    }

    .gn-message {
      margin-top: 20px;
      text-align: left;

      .content-message {
        background: $blue-4;
        border: 1px solid $blue-5;
        margin-top: 4px;

        &:before {
          left: 10px;
          border-bottom-color: $blue-5;
        }

        &:after {
          left: 12px;
          border-bottom-color: $blue-4;
        }
      }
    }
  }

  .feedback {
    font-size: 14px;
    border: 1px solid $gray-4;
    border-radius: 3px;
    margin-top: 30px;

    .space {
      padding: 10px 20px;
    }

    h3 {
      color: $black-1;
      margin: 10px 0 14px 0;
      font-size: 17px;
      font-weight: 600;
    }

    .finished-by {
      color: $orange-1;
      font-size: 16px;
      margin-right: 10px;
    }

    .date {
      font-size: 12px;
      margin-top: 6px;
    }

    .about {
      background: $gray-4;
      margin: 0;
    }
  }

  .answer {
    margin-bottom: 30px;
    padding: 2px 20px;

    .action {
      text-align: left;
      margin-top: 10px;
    }

    textarea {
      height: 120px;
    }

    .div-btn-attach {
      text-align: left;
    }

    .send-error {
      color: $red-1;
      font-size: 12px;
      margin: 12px 0;
    }

    @media all and (max-width: $screen-xs) {
      .div-btn-attach, .action {
        text-align: center;
      }
    }

    @media all and (max-width: $screen-md-until) {
      margin-bottom: 50px;

      .div-btn-attach, .action {
        margin-bottom: 10px;
      }
    }
  }
}
