.replace-billet {
  background-color: $gray-2;
  
  .container {
    margin: 0;
    width: 100%;

    @media screen and (max-width: 768px) {
      margin: 0
    }

    .content-2, .content-result {
      margin: 100px 0 100px 0;

      .form-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 207px;
          margin: 0 auto;
        }

        p {          
          color: $gray-27;
          font-family: $font-family-1-new-mark;
          font-size: 2rem;
          font-weight: 300;
          line-height: 28px;
          padding: 43px 110px 0 110px;
          margin: 0;
          text-align: center;

          @media screen and (max-width: 768px) {
            padding: 43px 10px 0 10px;
          }

          strong {
            font-weight: 700;
          }
        }
      }

      form {
        margin: auto;
        padding: 0 110px;

        @media screen and (max-width: 768px) {
          padding: 80px 10px 0 10px;
        }

        .form-selected {
          display: flex;
          justify-content: space-around;

          .input-group-radio {
            margin-top: 46px;
            margin-bottom: 57px;

            label {
              color: #f37021;
              font-family: $font-family-1-new-mark;
              font-size: 1.6rem;
              font-weight: 400;
              line-height: 1.5rem;
            }

            .input-helper {
              position: relative;
              display: inline-block;
              
              &:before {
                content: '';
                display: block;
                position: absolute;
              }

              &:after {
                content: '';
                display: block;
                position: absolute;
              }
            }
            
            .input-helper--radio {
              padding-left: 18px;
          
              &:after {
                top: -2px;
                left: -3px;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                border: 1px solid #707070;
                background-color: #f7f7f7;
                z-index: 0;
              }
              &:before {
                top: 1px;
                left: 0;
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                z-index: 1;
              }
            }

            input[type="radio"] {
              display: none;
          
              &:checked + label:before {
                background-color: #f37021;
              }
            }
          }

        }

        .input-group {
          margin-bottom: 42px;
          display: flow-root;

          label {
            color: $gray-27;
            font-family: $font-family-1-new-mark;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 24px;
          }

          span {
            color: #b50000;
          }

          input {
            border: 1px solid $gray-28;
            background-color: $white-1;
          }
        }

        .center-captcha {
          text-align: -webkit-center;
          margin-top: 14px;

          .allign {
            margin-bottom: 56px;
          }

          .btn {
            width: 304px;
            height: 48px;
            position: relative;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: $orange-1;
            color: $white-1;
            font-family: $font-family-2-regular-new-mark;
            font-size: 2.4rem;
            font-weight: 400;
            line-height: 2.25rem;
            text-transform: uppercase;
            border-radius: 0;
          }
        }
      }       
    }

    .content-result {
      .message-container {
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        padding: 70px 77px 0 77px;

        @media screen and (max-width: 768px) {
          padding: 80px 10px 0 10px;
        }

        @media screen and (min-width: 768px) and (max-width:$screen-xl){
          padding: 80px 45px 0 45px;
        }

        img.logo {
          margin: 0 auto;
          width: 207px;
        }

        .alert-card {
          border: 1px solid #e0e0e0;
          padding: 0 3.5rem;

          .icon {
            margin: 0 auto;
            background: #f7f7f7;
            width: 180px;
            top: -43px;
            position: relative;
            text-align: center;
        ​
            img {
              display: block;
              margin: 0 auto;
            }    
          }

          .card-content {
            position: relative;
            //top: -30px;

            .success-message, .error-message {
              padding-top: 0;
              padding-left:0;
              text-align: center;
              margin: 0;
              font-size: 4rem;
              font-weight: 300;
              letter-spacing: 0.48px;
              line-height: 6.8rem;
              text-transform: uppercase;
              font-family: $font-family-2-regular-new-mark;
              margin-bottom: 55px;
            }

            .error-message {
              color: #cc3300;

              strong {
                font-weight: 700;
              }
            }
            .success-message {
              color: #008000;

              strong {
                font-weight: 700;
              }
            }

            p {
              text-align: center;
              font-size: 2rem;
              margin: 0 0 25px 0;
              font-weight: 300;
              line-height: 2.8rem;
              font-family: $font-family-1-new-mark;
            }

            .dotted {
              height:1px;
              width: 100%;
              border-top: 1px dashed;
              border-color: #707070;
              opacity: 0.5;
              margin: 40px 0 1rem 0;
            }

            .botao-laranja-primario, .botao-laranja-secundario {
              text-align: center;
              display: block;
              padding: 10px 10px 8px;
              font-size: 2.4rem;
              line-height: 1em;
              width: 270px;
              margin: 0 auto;
              font-family: $font-family-2-regular-new-mark;
              box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
              text-decoration: none;
            }

            .botao-laranja-primario {
              background: $orange-1;
              color:$white-1;
              border: 2px solid $orange-1;
              margin-top: 35px;
              margin-bottom: 23px;

              &[disabled] { 
                background-color: #e7e7e7 !important;
                border-color: #e7e7e7 !important;
                color: #fff !important;
              }

              &:hover, &:focus {
                background: $orange-2;
                border-color: $orange-2;
              }
            }

            .botao-laranja-secundario {
              background-color: $white-1;
              color: $orange-1;
              border: 1px solid $orange-1;
              line-height: 1.2em;

              &[disabled] {
                background-color: #fff !important;
                border-color: #e7e7e7 !important;
                color: #e7e7e7 !important;
              }

              &:hover, &:focus {
                color: $orange-2;
                border-color: $orange-2;
              }
            }

            .btn {
              text-decoration: none;
              box-shadow: none !important;
              font-family: $font-family-1-new-mark;
              font-size: 1.8rem;

              &.btn-link {
                box-shadow: none !important;
                color: $blue-6;
              }
            }

            .btn-close-message {
              margin-left: auto;
              margin-right: auto;
              padding: 0;
              margin:65px 0 75px 0;
            }

            .mt-2 {
              margin-top: 20px;
            }

          }

        }

      } 
    }
    [data-aos].aos-animate {
      visibility: visible
    }

    @keyframes fadeInDown {
      0%, 30% {
          opacity: 0;
          -webkit-transform: translate3d(0, -100%, 0);
          -ms-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0)
      }
      100% {
          opacity: 1;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none
      }
    }
    
    .fadeInDown, body.blog-menu-active .blog-menu li {
        animation: fadeInDown 1s cubic-bezier(.215, .61, .355, 1) both
    }
  }
}