
.navigate {
  background-color: $blue-6;
  min-height: 348px;

  .knowit {
    display: flex;
    justify-content: center;
  }

  .container {   
    .content-navigate {
      .container {
        margin: 80px 4rem 0 4rem;
        width: auto;

        @media screen and (min-width: 1160px) {
          margin: 100px 0 0 45px;
        }

        h2 {
          margin: 0;
          text-align: left;
          letter-spacing: 0px;
          color: #FFFFFF;
          text-transform: uppercase;
          opacity: 1;
          font-family: $font-family-2-light-new-mark;
          font-size: 5.6rem;
          color: $white-1;
  
          strong {
            font-family: $font-family-2-regular-new-mark;
          }
        }
      }
    }

    .navigate-text {
      .container {
        margin: 125px 0 0 0;
        width: auto;

        @media screen and (max-width: 1160px) {
          margin: 4.5rem 4rem 0 4rem;;
        }

        @media screen and (max-width: 1160px) {
          margin-bottom: 85px;
        }
        .row {
          display: flex;
          flex-direction: row;
          justify-content: center;

          i {
            font-size: 6rem;
            color: $white-1;
          }
  
          p {
            color: $white-1;
            font-family: $font-family-1-new-mark;
            font-size: 2rem;
            font-weight: 400;
            margin: 0;
            
            strong {
              font-weight: 700;
            }
          }
        }

      }
    }
  }
}