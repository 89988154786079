.transfer-success {
  color: $gray-1;
  padding: 0 40px;

  .gn-loading-brand {
    width: 100%;
    padding: 150px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    &:h4 {
      font-size: 22px;
    }
  }

  .subtitle {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 30px 0;
  }

  .info {
    width: 80%;
    margin: 0 auto;

    h4 {
      color: $gray-1;
      font-weight: bold;
    }
  }

  .digit {
    margin-left: -3px;
  }

  .box {
    border: 1px solid $gray-4;
    border-radius: 2px;
    padding: 15px 20px;
    margin-bottom: 20px;

    @media screen and (max-width: $screen-sm-until) {
      padding: 5px 5px;
    }

    .box-title {
      font-weight: normal;
      font-size: 16px;
    }

    .protocol {
      font-size: 30px;
      font-weight: normal;
      font-family: 'Open Sans';
      color: $gray-14;
      @media screen and (max-width: $screen-sm-until) {
        font-size: 20px;
        text-align: center;
      }
    }

    &.box-warning {
      margin-top: 20px;
      width: 80%;
      margin: 0 auto;
      .warning {
        color: $orange-1;
        font-size: 16px;
      }
    }
  }

  .detail {
    margin-bottom: 20px;
    text-align: left;

    @media all and(max-width: $screen-sm) {
      margin-bottom: 0;
    }

    label {
      display: block;
      font-weight: normal;
    }

    span {
      font-weight: bold;
    }
  }

  .action {
    margin-top: 20px;
    text-align: center;
  }

  @media screen and (max-width: $screen-sm-until) {
    label {
      margin-top: 20px;
    }
  }

  @import 'transfer-about';

}
