.main-section {
  font-family: $font-family-1-new-mark;
}
.payer {
  max-width: $screen-lg-until;
  color: $gray-1;
  padding-top: 30px;
  padding-bottom: 50px;

  .payer-header{
    padding-left: 10px;
    padding-right: 10px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2-light-new-mark;
    font-size: 52px;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .subtitle {
    font-size: 34px;
    color: $orange-1;
    font-family: $font-family-2-book-new-mark;
    display: block;
    padding-bottom: 10px;
  }

  .sections {
    display: inline-block;
    font-size: 0;
    text-align: center;

    .sec-1, .sec-2, .sec-3 {
      display: inline-block;
      width: 33%;
      font-size: 14px;
      padding: 0 20px;
      vertical-align: top;
      text-align: center;
      height: 480px;

      p {
        text-align: left;
      }

      button, a {
        margin-top: 10px;
        font-family: $font-family-2-regular-new-mark;
        font-size: 18px;
        border-radius: 0;
      }

      button, a.btn{
        box-shadow: 2px 2px 6px rgba(0,0,0,.15);
      }
    }

    .sec-1, .sec-2 {
      border-right: 2px solid $gray-2;
    }
  }

  img {
    display: inline-block;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }


  @media screen and (max-width: $screen-md-until) {
    // max-width: $screen-md-until;

    .payer-header {
      margin-top: 30px auto;
      padding: 0 50px;
      text-align: center;
      .title {
        font-size: 45px;
      }
    }

    .sections {
      .sec-1, .sec-2, .sec-3 {
        width: 100% !important;
        height: auto;
        margin-top: 30px;

        p {
          text-align: center;
          padding: 0 50px;
        }
      }

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
