.subscription-error {
  max-width: $screen-sm-until;
  color: $gray-1;

  h3 {
    color: $red-1;
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin: 0;
  }

  .links {
    margin-top: 20px;
    margin-left: 30px;

    div {
      display: block;

      a {
        display: inline-block;
        border: 1px solid $gray-4;
        padding: 5px 15px;
        margin: 2px;
      }
    }
  }
}
