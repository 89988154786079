.account-sent {
  padding: 0 50px;
  margin-top: 50px;
  margin-bottom: 100px;

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    @media all and (max-width: $screen-sm-until) {
      font-size: 25px;
      text-align: center;
    }
  }

  p {
    width: 80%;
    margin-bottom: 40px;

    @media all and (max-width: $screen-md-until) {
      width: 100%;
    }
  }

  .little {
    width: 60%;
    margin-top: 30px;
  }

  .success-message {
    margin: 20px 0;
    color: $green-1;
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
    width: 60%;

    i {
      font-size: 25px;
      vertical-align: middle;
    }

    @media all and (max-width: $screen-md-until) {
      width: 100%;
    }
  }
}