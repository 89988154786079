.notices {
  padding-left: 20% !important;
  padding-right: 20% !important;
  position: relative;

  .gn-loading-brand {
    width: 100%;
    padding: 40%;
  }

  h1 {
    color: #EE6A27;
    margin-bottom: 30px;
  }

  .plain {
    margin-bottom: 30px;
    .plain-content {
      background: white;
      padding: 20px;
      border-radius: 5px;

      .regards {
        margin-top: 20px;
      }
    }
  }

  .footer {
    margin-top: 20px;
    text-align: center;

    label {
      font-size: 15px;
      font-weight: bold;
      padding: 2px 10px;
      cursor: pointer;

      input,
      span {
        vertical-align: middle;
        height: 30px;
      }
    }

    .border {
      border: 1px solid #D7D7D7;
      width: 310px;
      border-radius: 3px;
      margin-bottom: 30px;
    }
  }

  button {
    background: #D1D2D4;
    margin-bottom: 30px;
    font-weight: bold;
    color: #6B6C6E;
    &:hover {
      color: rgba(0, 6, 19, 0.69);
    }
  }
}
