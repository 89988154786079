.refund-access {
  margin: 30px auto;
  width: 100%;
  text-align: left;

  .title {
    color: $orange-1;
    font-family: "Open Sans", "sans-serif";
  }

  @media all and (max-width: $screen-md-until) {
    margin: 0 10px;
  }

  p {
    margin: 30px 0;
  }

  form {
    text-align: left;
    display: inline-block;

    .form-group {
      width: 300px;

      input {
        text-align: left;
        height: 45px;
      }
    }

    .captcha {
      width: 300px;
    }
  }
}
