.subuser-form {
  max-width: $screen-sm-until;
  color: $gray-1;

  .gn-loading-brand {
    width: 100%;
    padding: 150px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;
  }

  .subtitle {
    font-weight: bold;
    font-size: 20px;
    margin: 20px 0 30px 0;
  }

  label {
    font-weight: normal;
  }

  .with-space {
    padding-right: 15px;
  }

  .terms {
    font-size: 12px;
    margin: 0 0 15px 0;
  }
}
