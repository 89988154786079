//
// Alerts
// --------------------------------------------------

// Base styles
// -------------------------

.alert {
  margin-bottom: $line-height-computed;
  border: 1px solid transparent;
  border-radius: $alert-border-radius;
  padding: 10px 40px 10px 60px;
  position: relative;
  text-align: center;
  z-index: 1;
  min-height: 41px;
  font-family: 'Arial';
  font-weight: 200;
  font-size: 14px;
  transition: transform .25s ease-in-out, opacity .2s ease-in-out;

  &:before {
    font-family: 'icomoon';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 44px;
    border-right: 1px solid #fff;
    z-index: -1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 29px;
    padding-left: 2px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 45px;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  & + .alert.ng-leave {
    display: none;
  }

  &.ng-hide-add, &.ng-hide-remove-active, &.ng-enter-active, &.ng-leave {
    transform: scale(1);
    opacity: 1;
  }

  &.ng-hide-remove, &.ng-hide-add-active, &.ng-enter, &.ng-leave-active {
    transform: scale(0.7);
    opacity: 0;
  }

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing $headings-color
    color: inherit;
  }

  // Adjust close link position
  .close {
    position: absolute;
    top: 7px;
    right: 10px;
    color: inherit;
    background: #fff;
    border-radius: 4px;
    padding: 3px;
    height: 25px;
    width: 25px;

    span {
      display: inline-block;
      width: 20px;
      height: 17px;
      line-height: 0.7;
      vertical-align: top;
      padding-top: 1px;
    }
  }

  // Provide class for links that match alerts
  .alert-link {
    font-weight: $alert-link-font-weight;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert-dismissible {

}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text, $alert-success-icon);
}

.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text, $alert-info-icon);
}

.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text, $alert-warning-icon);
}

.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text, $alert-danger-icon);
}
