.contestation {
  max-width: $screen-md;
  padding: 10px 30px 16px 30px;
  font-family: $font-family-1;

  @media all and (max-width: $screen-xs-until) {
    padding: 10px 10px 16px 10px;
  }

  h1 {
    color: $orange-1;
    font-family: $font-family-2;

    i {
      cursor: pointer;
      color: $blue-1;
      font-weight: normal;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      display: inline-block;
    }
  }

  h3 {
    color: $orange-1;
    font-family: $font-family-2;
  }

  h4 {
    font-weight: 600;
    margin-top: 20px;
  }

  h5 {
    font-weight: 700;
    margin-top: 16px;
  }

  .warning-message {
    color: $red-1;
    font-size: 18px;
    font-family: $font-family-2;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .box {
    border: 1px solid $gray-4;
    border-radius: 2px;
    padding: 0 20px;
  }

  .row {
    &.with-margin {
      margin: 20px 0 36px 0;
    }
  }

  .file-list {
    font-size: 12px;
    margin-top: 10px;
    padding: 0 0 10px 20px;

    li {
      padding: 3px 0;
    }

    .gn-loading {
      margin: 0;

      .gnld-icon {
        font-size: 14px;
        &:before {
          width: auto;
          height: auto;
          line-height: 1;
        }
      }
    }

    .filename {
      max-width: 200px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      padding-top: 2px;
    }

    .wrong-file {
      color: $red-1;
      &:active,
      &:focus,
      &:hover,
      &:visited {
        text-decoration: line-through;
      }
    }

    .remove-file {
      color: $orange-1;
      cursor: pointer;
    }

  }

  @media all and (max-width: $screen-sm) {
    h1 {
      font-size: 25px;
      text-align: center;
    }

    h3 {
      font-size: 20px;
      text-align: center;
    }
  }

  @import '_contestation-new';
  @import '_contestation-confirm';
  @import '_contestation-detail';
  @import '_contestation-access';
  @import '_contestation-recover';
  @import '_contestation-recovered';
  @import '_contestation-info';
}
