.contestation-confirm {

  .indent {
    margin-left: 16px;
    margin-right: 16px;
  }

  .charge-data {
    text-align: center;
    font-size: 0;

    div {
      margin: 20px 0;
      display: inline-block;
      width: 25%;
      vertical-align: top;
      &:not(:last-child) {
        border-right: 1px solid $gray-3;
      }
      @media (max-width: $screen-md-until) {
        width: 50%;
        &:nth-child(2) {
          border: none;
        }
      }
      @media (max-width: $screen-xs-until) {
        width: 100%;
        border: none !important;
      }
    }

    .tag {
      display: block;
      font-size: 14px;
    }

    .value {
      font-size: 20px;
      font-family: $font-family-2;
      font-weight: 600;
      display: block;
      margin-top: 4px;
    }

    .icon-meu-dinheiro {
      color: $green-1;
    }

    .icon-calendar {
      color: $gray-3;
    }

    .method-icon {
      color: $orange-1;
      font-size: 16px;
    }
  }

  .account-owner {
    font-size: 0;
    &:before {
      content: '\e75c';
      font-family: 'icomoon';
      font-size: 30px;
      display: inline-block;
      background: $gray-4;
      padding: 2px 6px;
      vertical-align: top;
      line-height: 1;
      margin-top: 6px;
      @media (max-width: $screen-xs-until) {
        display: none;
      }
    }

    .data {
      border: 1px solid $gray-4;
      display: inline-block;
      padding: 0 14px 14px;
      width: 80%;

      div {
        width: 50%;
        display: inline-block;
        font-size: 14px;
        margin-top: 14px;
        @media (max-width: $screen-sm-until) {
          width: 100%;
        }
      }
    }
  }

  .table {
    tbody,
    thead {
      td:not(:first-child),
      th:not(:first-child) {
        text-align: center;
      }
    }

    tfoot {
      td {
        text-align: right;
        font-weight: 600;
        font-family: $font-family-2;
        font-size: 15px;
      }
    }

    &.items-table {
      tbody,
      thead {
        tr {

          td,
          th {
            width: 20%;
            &:first-child,
            th:first-child {
              width: 40%;
            }
          }
        }
      }
    }

    &.shippings-table {
      tbody {

        tr {
          td:nth-child(2) {
            width: 20%;
            min-width: 100px;
          }
        }
        tr:first-child td {
          border: none;
        }

      }
    }

  }

  textarea {
    margin: 10px 0;
    height: 100px;
    resize: none;
  }

  small {
    font-weight: normal;
    color: $gray-5;
  }

  .input-digit {
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }

  .separator {
    margin-top: 7px;
  }

  .submit-container {
    margin-top: 36px;
  }

}
