.email-redefine-error,
.email-redefine-success,
.email-redefine-expired {
  max-width: 400px;
  margin: 0 auto;

  h2 {
    font-size: 16px;
    font-weight: 600;

    i {
      font-size: 25px;
      vertical-align: middle;
    }
  }

  p {
    text-align: left;
  }

  .btn {
    margin-top: 30px;
  }
}

.email-redefine-success {
  h2 {
    color: $green-1;
  }
}

.email-redefine-error {
  h2 {
    color: $red-1;
  }

  p {
    text-align: center;
  }
}

.email-redefine-expired {
  h2 {
    color: $red-1;
  }
}
