.contestation-recover {
  .form-group {
    width: 300px;
    margin-bottom: 15px;

    input {
      text-align: center;
      height: 45px;
    }
  }

  .contestation-link {
    margin-top: 25px;
  }
}
