.error-page {
  text-align: center;

  .title-404, .title-500 {
    display: block;
    font-size: 200px;
    font-family: Arial, 'sans-serif';
    font-weight: bold;
  }

  .message-404, .message-500, .message-offline {
    font-size: 30px;
    display: block;
  }
}