//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: $border-radius-base;

  > li {
    display: inline-block;
    font-size: 12px;

    a {
      color: $breadcrumb-color;
      font-family: Arial, sans-serif;
      
      &:hover,
      &:focus,
      &:active {
        color: $breadcrumb-hover-color;
      }
    }

    + li:before {
      content: "#{$breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      color: $breadcrumb-color;
      margin-left: 4px;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
