.geolocation {
	width: 440px;
	margin: 0 auto;

	@media all and (max-width: $screen-lg-until){
		padding: 0 20px;
	}

	@media all and (max-width: $screen-sm-until){
		width: 100%;
	}

	div>h1 {
		@media all and (max-width: $screen-lg-until){
			padding: 45px 0 35px 0;
		}
	}

	.titulo-h1-laranja {
		color:  $orange-1;
		margin-bottom: 20px;
	}

	.title-second {
		margin-top: 40px;
		margin-bottom: 30px;

		div {
			display: inline-block;

			@media all and (max-width: $screen-sm-until) {
				display: block;
			}
		}

		.icone-world {
			margin-right: 10px;
			vertical-align: top;

			@media all and (max-width: $screen-lg-until) {
				vertical-align: sub;
			}

			@media all and (max-width: $screen-sm-until) {
				text-align: center;
				margin: 0;
			}

			.icon-grid-world {
				color: $blue-1;
				font-size: 42px;;
			}
		}

		.description {
			width: 80%;

			@media all and (max-width: $screen-sm-until) {
				width: 100%;
			}

			.info {
		    	font-family: "Open Sans";
				font-size: 23px;
				font-weight: 300;
    			text-align: left;

    			@media all and (max-width: $screen-sm-until) {
					text-align: center;
				}
			}
		}
	}

	.title-third{
		p{
			text-align: left;
		}
	}

	.notification {
		color: $yellow-2;
		font-size: 17px;
		font-family: "Open Sans";
	  font-weight: 400;
	  margin-bottom: 35px;
		line-height: 24px;
		text-align: left;
	}

	.layout {
		margin-top: 20px;
		text-align: left;

		.form-group{
			margin: 15px auto;
		}

		.totp-info, .sms-info{
      padding: 10px;

      &.checked{
        border-radius: 5px;
        border: 1px solid #eeeeee;
      }
    }

    button[type="submit"]{
    	text-align: center;
    	margin-top: 20px;
    }

    .gn-radio {
      font-size: 13px;
      :before {
        margin-right: 10px;
      }
    }

		.sms {
			padding: 30px 20px;
			border-radius: 5px;
      		border: 1px solid #eeeeee;

			label {
				display: block;
			}

			.sms-body {
				display: inline-block;
				vertical-align: super;
			}

			.sms-input {
				display: inline-block;
				width: 100%;
				vertical-align: top
			}

			.btn {
		    display: inline-block;
				margin-top: 0px;
				margin-left: 20px;

				@media all and (max-width: $screen-xs-until) {
					margin: 0 auto;
					margin-top: 15px;
					display: block;
				}
			}

			.not-received-code {
				margin-top: 25px;
			}
		}

		.call {
			
			padding: 30px 20px;
			border-radius: 5px;
      		border: 1px solid #eeeeee;

      		.description {
				&:before{
					content: "";
					border: 1px solid #eeeeee;
					width: 80%;
					margin: 30px auto 20px auto;
					height: 1px;
					display: block;

				}

				@media all and (max-width: $screen-sm-until) {
					width: 100%;
					margin-bottom: 40px;
				}
			}

			.called {
				margin-top: 36px;
				text-align: center;

				@media all and (max-width: $screen-sm-until) {
					width: 100%;
					padding: 0 10px;
					text-align: justify;
				}
			}

			.border {

				@media all and (max-width: $screen-sm-until) {
					border: none;
				}

				.form-input {
					display: inline-block;
					width: 100%;

					@media all and (max-width: $screen-sm-until) {
						display: block;
						margin: 0 auto;
					}
				}
			}
			
			/*.col-1 {
				width: 40%;
				display: inline-block;
				text-align: center;
				vertical-align: top;

				@media all and (max-width: $screen-sm-until) {
					width: 100%;
					margin-bottom: 40px;
				}

				.border {
					border-right: 1px solid #e7e7e8;
					border-radius: 0;
					padding: 0 10px;

					@media all and (max-width: $screen-sm-until) {
						border: none;
					}

					.form-input {
						display: inline-block;
						width: 60%;
						margin-bottom: 12px;

						@media all and (max-width: $screen-sm-until) {
							display: block;
							margin: 0 auto;
							margin-bottom: 12px;
						}
					}
				}
			}*/

			/*.col-2 {
				width: 59%;
				display: inline-block;
				padding: 0 40px;

				@media all and (max-width: $screen-sm-until) {
					width: 100%;
					padding: 0 10px;
					text-align: justify;
				}
			}*/
		}
	}
}
