// Custom scrollbar
.gn-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d1d2d4;

  }
  &::-webkit-scrollbar-track {
    background-color: #e7e7e9;
  }
}