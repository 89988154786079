.contestation-info {
  padding: 15px 0;

  p {
    margin: 14px 0;
  }

  .title {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: bold;
  }

  .image  {
    width: 100%;
  }

  ul {
    padding-left: 16px;

    li {
      padding-bottom: 10px;
    }
  }

  .cols {
    padding-right: 25px;
    margin-bottom: 20px;
  }

  .button {
    margin: 25px 0 50px 0;
    font-family: 'Open Sans';
  }

  .listed-info {
    font-family: 'Open Sans';
    margin: 15px 0;

    .number {
      font-size: 18px;
      font-weight: bold;
      display: inline-block;
      vertical-align: top;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: $gray-4;
      text-align: center;
      margin: 10px;
    }

    .message {
      font-size: 15px;
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 55px);
    }
  }

  .link {
    margin-bottom: 30px;

    a {
      font-family: 'Open Sans';
      font-size: 18px;
      text-decoration: underline;
    }
  }
}
