.container-duplicate {
  @media screen and (min-width: 768px) and (max-width:1160px) {
    margin-right: 0;
    margin-left: 0;
    width: 100%
  }
  .row {
    display: flex;
    flex-wrap: wrap;

    .duplicate {
      .container {
        margin: 0 4rem 0 4rem;
        width: auto;

        .content-1 {
          margin: 100px 0 0 0;

          @media screen and (max-width: 1160px) {
            margin-bottom: 80px;
          }

          h1 {
            margin: 0;
          }

          .title-1 {
            span {
              font-family: $font-family-2-light-new-mark;
              font-size: 5.6rem;
              color: $orange-1;

              strong {
                font-family: $font-family-2-regular-new-mark;
              }
            }
          }

          p {
            margin-top: 16px;
            color: $gray-25;
            font-family: $font-family-1-new-mark;
            font-size: 2.4rem;
            font-weight: 300;
          }

          hr {
            width: 138px;
            height: 5px;
            background-color: #f26522;
            margin: 34px 0;
          }

          .infos {
            margin: 2rem 0;

            p {
              margin: 0;
              color: $gray-25;
              font-family: $font-family-1-new-mark;
              font-size: 2rem;
              font-weight: 300;
              letter-spacing: 0.16px;
              line-height: 30px;

              strong {
                font-weight: 700;
              }
            }

            .list-check {
              list-style: none;
              color: $gray-25;
              font-family: $font-family-1-new-mark;
              font-size: 2rem;
              font-weight: 300;
              letter-spacing: 0.16px;
              line-height: 30px;
              padding: 10px 0px;
          
              li {
                display: block;
                position: relative;
                padding-left: 2rem;
                margin-bottom: 1.2rem;

                &:last-child {
                  margin-bottom: 0;
                }
              }
          
              li:before {
                content: '';
                display: inline-block;
                width: 1.5rem;
                height: 1.2rem;
                background: url('../../../../img/duplicate/icon-check.svg') no-repeat bottom left;
                background-size: 1.5rem 1.2rem;
                position: absolute;
                left: 0;
                top: 0.8rem;
              }
            }
          }

          .btn-1 {
            font-size: 2.7rem;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1em;
            text-transform: uppercase;
            text-align: center;
            background: #f37021;
            display: inline-block;
            position: relative;
            padding: 1.2rem 2rem 1.1rem;
            margin: 0;
            box-shadow: 0 .3rem .5rem rgba(0,0,0,.15);
            overflow: hidden;
            box-sizing: border-box;

            a {
              color: #ffffff;
              font-family: $font-family-2-regular-new-mark;
              font-size: 24px;
              font-weight: 400;
              letter-spacing: 0.29px;
              text-transform: uppercase;
            }

            @media screen and (min-width: 768px) {
              display: none;
            }
          }

          .btn-link {
            color: #00bbce;
            font-family: $font-family-1-new-mark;
            font-size: 2rem;
            font-weight: 300;
            letter-spacing: 0.24px;
            line-height: 14px;
            display: none;
            text-decoration: none;
            margin-top: 38px;
            padding: 0;

            @media screen and (min-width: 768px) {
              display: inline;

              i {
                margin-right: 0.5rem;
              }
            }
          }

          .btn-acessar-conta {
            width: 100vw;
            position: relative;
            margin-left: -5vw;

            @media screen and (max-width: 768px) {
              margin-left: -11vw;
            }
          }

          .partners {
            border: 1px solid #e0e0e0;
            margin: 115px 0 95px 0;

            .box-title {
              margin: 0 auto;
              background: $white-1;
              width: 280px;
              top: -20px;
              position: relative;
              color: $gray-27;
              font-family: $font-family-2-light-new-mark;
              font-size: 4rem;
              font-weight: 300;
              letter-spacing: 0.48px;
              line-height: 4rem;
              text-align: center;

              strong {
                font-weight: 700;
              }
            }

            p {
              margin: 20px 0 0 0;
              color: $gray-25;
              font-family: $font-family-1-new-mark;
              font-size: 2rem;
              font-weight: 300;
              text-align: center;
            }

            .partners-content {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
              margin: 45px 0;

              @media screen and (min-width: 768px) {
                flex-direction: row;
                justify-content: space-around;
              }

              .partner-bank {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @media screen and (max-width: 768px) {
                  padding-top: 10px;
                  padding-bottom: 10px;
                }

                img {
                  max-width: 196px;
                  height: 28px;
                }

                a {
                  margin: 25px 0 0 0;
                  color: #00bbce;
                  font-family: $font-family-1-new-mark;
                  font-size: 1.8rem;
                  font-weight: 300;
                  text-decoration: none;

                  i {
                    margin-right: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@import 'replace';
@import 'navigate';
@import 'billet';