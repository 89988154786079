@mixin round-content($content) {
  &:before {
    content: $content;
    padding-left: 20px;
    padding-top: 15px;
    font-weight: bold;
    font-size: 18px;
    left: 10px;
    top: 0;
    position: absolute;
    border-radius: 50%;
    background: $orange-1;
    opacity: 0.8;
    color: white;
    width: 50px;
    height: 50px;
    -webkit-box-shadow: 1px 2px 2px 0px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 1px 2px 2px 0px rgba(50, 50, 50, 0.75);
    box-shadow: 1px 2px 2px 0px rgba(50, 50, 50, 0.75);

    @media all and (max-width: $screen-sm-until) {
      width: 40px;
      height: 40px;
      padding-left: 15px;
      padding-top: 10px;
      left: 0;
    }
  }
}
@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// scale
@mixin scale($scale) {
   @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}
@mixin transition-delay($delay) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

@mixin flex ($justify: center, $align: center, $direction: row, $wrap: wrap ) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    align-content: $align;
    flex-wrap: $wrap;
    flex-direction: $direction;
  }

@mixin absolute ($top: 0, $right: auto, $bottom: auto, $left: 0) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }

@mixin font( $tamanho: 1.8rem, $peso: 400, $line-height: 1em, $letter-spacing: 0  ){
    //font-family: $font-family;
    font-size: $tamanho;
    font-weight: $peso;
    letter-spacing: $letter-spacing;
    line-height: $line-height;
  }