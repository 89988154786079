.account-code {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 50px;

  @media all and (max-width: $screen-md-until) {
    padding: 0 10px;
  }

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    @media all and (max-width: $screen-sm-until) {
      font-size: 25px;
      text-align: center;
    }
  }

  form {
    padding: 30px 0;
    width: 80%;

    @media all and (max-width: $screen-md-until) {
      padding: 15px 0;
      width: 100%;
    }

    p {
      width: 70%;
      margin-bottom: 20px;
      color: $yellow-2;
      font-size: 16px;

      i {
        font-size: 18px;
      }

      @media all and (max-width: $screen-md-until) {
        width: 100%;
      }
    }

    .form-group:not(.captcha) {
      display: inline-block;
      padding-right: 30px;
      width: 35%;
      vertical-align: top;

      @media all and(max-width: $screen-sm-until){
        width: 100%;
        padding: 0;
      }
    }

    .not-received-code {
      display: inline-block;
      padding: 10px;
      border: 1px solid $gray-4;
      border-radius: 2px;
      margin-top: 20px;
      vertical-align: top;

      @media all and(max-width: $screen-xs-until){
        width: 100%;
        text-align: center;
      }
    }

    .second-column {
      display: inline-block;
      width: 64%;
      border-left: 1px solid $gray-4;
      margin-bottom: 10px;
      padding-left: 30px;

      @media all and(max-width: $screen-sm-until){
        width: 100%;
        border: none;
        padding: 0;
      }
    }

    .receive-code {
      vertical-align: top;

      .message {
        padding-right: 15px;
        display: inline-block;
        width: calc(100% - 150px);
        vertical-align: middle;
      }

      .button {
        vertical-align: middle;
        display: inline-block;
      }

      @media all and(max-width: $screen-xs-until){
        .message {
          width: 100%;
        }

        .button {
          width: 100%;
          text-align: center;
          margin: 10px 0;
        }
      }
    }

    .called {
      padding: 20px 0 20px 30px;
      vertical-align: top;

      @media all and(max-width: $screen-sm-until){
        padding: 0;
      }
    }
  }

  .captcha {
    width: 300px;
    margin: 20px 0;
  }

  .actions {
    .btn {
      margin-top: 10px;
      margin-right: 15px;
    }

    @media all and(max-width: $screen-xs-until){
      text-align: center;
    }
  }
}
