.account-success {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 50px;

  .title {
    color: $orange-1;
    font-family: $font-family-2;

    @media all and (max-width: $screen-sm-until) {
      font-size: 25px;
      text-align: center;
    }
  }

  p {
    width: 80%;

    @media all and (max-width: $screen-md-until) {
      width: 100%;
    }
  }

  .success-message {
    margin: 20px 0;
    color: $green-1;
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
    width: 60%;

    i {
      font-size: 25px;
      vertical-align: middle;
    }

    @media all and (max-width: $screen-md-until) {
      width: 100%;
    }
  }

  .box {
    margin-top: 20px;
    border: 1px solid $gray-2;
    border-radius: 5px;
    padding: 20px;
    display: flex;

    & > div{
      width: 100%;
      flex-grow: 1;
      &:not(:last-child){
        margin-right: 20px;
      }
    }

    h2 {
      color: $orange-1;
      font-size: 22px;
      margin: 0;
      margin-bottom: 20px;
    }

    ul:not(.round-ul) {
      list-style: none;
      margin-left: 0;
      padding-left: 1.3em;
      text-indent: -1.3em;

      li:not(.round) {
        margin-bottom: 10px;
        width: 250px;
        &:before {
          color: $gray-3;
          font-family: 'icomoon';
          content: '\e643';
          display: block;
          float: left;
          width: 1.3em;
        }
      }
    }

    .round-ul {
      list-style: none;
      margin-left: 0;
      padding-left: 1.3em;
      text-indent: -1.3em;

      li {
        margin-bottom: 5px;
        margin-top: 5px;
        width: 250px;
        &:before {
          color: $gray-3;
          content: '•';
          display: block;
          float: left;
          width: 1.3em;
        }
      }
    }

    em {
      font-size: 11px;
      width: 70%;
      display: block;
    }

    @media all and (max-width: $screen-md-until) {
      &{
        padding: 30px;
        text-align: justify;
        display: block;
      }

      & > div{
        display: block;
        margin-right: 0;  
      }

      h2 {
        margin-top: 0px;
        font-size: 18px;
      }

      ul:not(.round-ul) {
        margin-bottom: 50px;
        li:not(.round){
          width: 100%;
        }
      }

      em {
        width: 100%;
      }
    }
  }

  .enter {
    margin-top: 20px;
    text-align: center;
  }

  .action {
    text-align: center;
  }


  @media all and (max-width: $screen-md-until) {
    .box {
      padding: 30px;
      ul {
        li {
          width: 100%;
        }
      }
    }

    .action {
      margin-top: 30px;
    }
  }

  @media all and (max-width: $screen-sm-until) {
    &{
      padding: 0 30px;
    }

    .box{
      border: none;
      padding: 0;
    }
  }
}
