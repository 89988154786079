.header {
  border-bottom: 4px solid $orange-1;

  .content-header {
    font-size: 0;
    padding: 15px 0;

    .logo {
      line-height: 55px;

      @media all and(max-width: $screen-sm-until) {
        text-align: center;
        margin-bottom: 15px;
      }

      img {
        width: 200px;
        height: 40px;
      }
    }

    .security-shield {
      i {
        font-size: 55px;
        color: $orange-1;
        vertical-align: middle;
      }

      div {
        display: inline-block;
        width: 80%;
        vertical-align: middle;

        p {
          margin-bottom: 0;

          &.title {
            font-size: 15px;
            font-weight: bold;
          }

          &:not(.title) {
            font-family: 'Arial';
            font-size: 10px;
          }
        }
      }
    }
  }
}
