.subscription-confirmation {
  max-width: $screen-sm-until;
  color: $gray-1;

  .gn-checkbox {
    display: inline-block;
  }

  .box {
    border: 1px solid $gray-4;
    border-radius: 2px;
    padding: 10px;
    margin: 3px 0;
    font-size: 13px;

    p {
      margin: 0;

      &.bold {
        margin-bottom: 5px;
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  h4 {
    margin-top: 0;
    font-size: 16px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 5px;

      &:not(:last-child):not(:first-child) {
        border-bottom: 1px solid $gray-4;
      }
    }
  }

  .gn-checkbox {
    label {
      font-weight: normal;

      &:before {
        margin-right: 10px;
      }
    }
  }

  .action {
    margin-top: 20px;
  }
}
