body{
  font-family: $font-family-1-new-mark;
  font-size: 16px;
  color: #6e6f71;
}

.header-gateway {
  height: 79px;
  border-bottom: 1px solid #dcdcdc;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(196, 196, 196, 0.09);
  -moz-box-shadow: 0px 3px 5px 0px rgba(196, 196, 196, 0.09);
  box-shadow: 0px 3px 5px 0px rgba(196, 196, 196, 0.09);

  .center-content {
    width: $screen-lg-until;
    min-width: 300px;
    margin: 0 auto;
    padding: 0;

    @media all and (max-width: $screen-lg-until) {
      width: auto;
      margin: 0 10px;
      &:before {
        display: none;
      }
    }
  }

  a{
    text-decoration: none;
  }
  
  h1#marca{
    float: left;
    margin: 15px 0 0;
    padding: 0;
    height: 59px;
    width: 190px;
  }

  #navegacaoLocal {
    float: left;
    clear: none;
    margin-left: 10px;
    margin-right: 10px;

    ul{
      padding: 0 0 0 10px;
      list-style: none; 

      li{
        float: left;
        margin: 0 2px;

        a{
          text-transform: uppercase;
          font-size: .87em;
          padding: 36px 12px 25px;
          height: 18px;
          display: block;
        }
      }
    }
  }

  #navegacaoGlobal {
    padding: 0;
    float: right;
    margin-top: 4px;

    ul {
      list-style: none;
      padding-left: 0;

      li{
        margin-top: -4px;
        float:left;
        margin: 0 8px;
        padding: 18px 0 0 0;

        &.menuitem-desenvolvedores, &.menuitem-telefone {
          font-size: 13px;
          padding: 33px 0 0 0;
          a{
            color: #f26522;
          }
          a:hover{
            color: #c84d27;
          }
        }

        a{
          text-decoration: none;
        }

        &.menuitem-cadastro, &.menuitem-entrar{
          margin: 0 5px;
        }

        &.menuitem-entrar a:before {
          content: '\e951';
          font-family: "icomoon";
          margin-right: 10%;
        }
      }
    }
  }

  .botao-laranja-primario {
    text-align: center;
    display: block;
    background: #f26522;
    color: #fff;
    padding: 9px 13px;
    font-family: $font-family-2-regular-new-mark;
    font-size: 1.1em;
    line-height: 1em;
    border: 2px solid #f26522;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);

    &:hover, &:focus {
      background: #c84d27;
      border-color: #c84d27;
    }
  }

  .botao-laranja-secundario {
    text-align: center;
    display: block;
    background: none;
    color: #f26522;
    padding: 9px 15px;
    font-family: $font-family-2-regular-new-mark;
    border: 1px solid #f26522;
    line-height: 1.2em;
    font-size: 16px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);

    &:hover, &:focus {
      color: #c84d27;
      border-color: #c84d27;
    }
  }

  .nav-toggle {
    display: none;
    width: 14%;
    text-align: right;
    padding: 10px 0;
    position: relative;
    float: right;
    margin-right: 20px;

    button {
      display: inline-block;
      width: 42px;
      height: 34px;
      background-color: #00b4c5;
      color: $white-1;
      border: none;
      position: relative;
      outline: none;
      top: 15px;
    }

    .open-menu:before {
      font-family: 'icomoon';
      content: '\e953';
      position: absolute;
      top: 6px;
      left: 11px;
      font-size: 17px;
    }

    .close-menu:before {
      font-family: 'icomoon';
      content: '\e952';
      position: absolute;
      top: 6px;
      left: 11px;
      font-size: 17px;
    }

  }

  .mobile-nav {
    display: none;
    padding-top: 35px;

    h2{
      font-family: $font-family-2-book-new-mark;
      text-transform: uppercase;
      color: #f37021;
      font-size: 34px;
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 5px;
      display: block;
      padding-left: 45px;
    }

    ul.menu-lista{
      padding-left: 45px;
      padding-top: 10px;
    }

    ul.sub-menu{ 
      padding-left: 15px;
    }

    ul li{
      list-style: none;

      a {
        display: block;
        width: 100%;
        margin: 10px 0;
        font-size: 1em;
        height: 18px;
      }
    }

    .botao-laranja-primario, .botao-laranja-secundario{
      text-transform: uppercase;
      margin: 10px 45px;
      font-size: 1.2em;
    } 
  }

  @media screen and (max-width: 1180px){
    
    #navegacaoLocal {
      ul{ 
        li{
          margin: 0 1px;
          a {
            padding: 36px 4px 25px;
          }
        }
      }
    }
  }

  @media all and (max-width: 1070px){ 
    h1#marca{
      width: 140px;
    }

    #navegacaoLocal {
      margin: 0;
      ul li{
        margin: 0 1px;
      }
    }
  }

  @media all and (min-width:561px) and (max-width: 1024px){ 

    h1#marca{
      width: 190px;
    }
    
    #navegacaoLocal {
      display: none;
    }

    .menuitem-desenvolvedores, .menuitem-telefone{
      display: none;
    }

    &.show-menu {
      .mobile-nav {
        box-shadow: 0 3px 7px rgba(0,0,0,.05);
        overflow-x: hidden;
        overflow-y: scroll;
        display: block;
        padding-top: 0;
        display: block;
        padding-top: 0;
        z-index: 50;
        background-color: #fff;
        width: 48%;
        position: absolute;

        > a{
          display: none;
        }
      }
    }

    .nav-toggle {
      display: block;
      float: left;
      margin-right: 0;
      width: 60px;
      padding: 10px 0;
      border-left: 1px solid #dcdcdc;
      margin-left: 20px;
      margin-top: 19px;
      height: 44px;

      button{
        top: -5px;
      }
    }
  }

  @media all and (max-width:560px){
    h1#marca{
      height: 41px;
      width: 191px;
    }

    &.show-menu {
      border-bottom: none;
      box-shadow: none;
      .mobile-nav {
        display: block;
      }
    }

    .nav-toggle{
      display: block;
    }

    #navegacaoLocal, #navegacaoGlobal {
      display: none;
    }

    .menuitem-entrar:before {
      content: '\e951';
      font-family: "icomoon";
      margin-right: 3%;
    }

    &.show-menu {
      padding-bottom: 20px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      min-width: 300px;
      border-bottom: none;
      box-shadow: none;

      .local-nav {
        display: none;
      }
      
      .center-content{
        margin: 0 10px;
      }
    }
  }
}

